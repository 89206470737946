import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import React from "react";
import { BaseColorsTheme } from "../../../assets/styles/ThemeType";

interface MbjBackCtrlProps {
  /**
   * Classe HTML de l'icône retour
   */
  className?: string;

  /**
   * Hauteur de l'icône retour :
   */
  height?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du bouton sur la page :
   */
  themeColor?: BaseColorsTheme;
  /**
   * Couleur  de base de MbjUserIcon
   */
  basicColor?: "white" | "black";
  /**
   * Doit t-il être désactivé ?
   */
  disabled?: boolean;
  /**
   * Fonction onClick personnalisée ?
   */
  onClick?: React.MouseEventHandler<HTMLOrSVGElement>;
}

function MbjBackCtrl(props: MbjBackCtrlProps) {
  const navigate = useNavigate();

  function handleClick(e: any) {
    if (props.disabled) {
      return;
    } else {
      if (props.onClick) {
        return props.onClick(e);
      } else {
        return navigate(-1);
      }
    }
  }

  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 123.53353 192.6075"
      onClick={handleClick}
    >
      <path
        d="M112.88823 10.6453 10.64471 96.30375l102.24352 85.65844"
        fill="none"
        strokeWidth="21.2906"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
/**
 * Composant flèche de retour faite pour revenir en arrière (on peut tout de même lui donner
 * une fonction personnalisée permettant d'autres usages.)
 */
export const MbjBack = styled(MbjBackCtrl)((props) => ({
  height: props.height,
  cursor: props.disabled ? "default" : "pointer",

  "&:hover": {
    path: {
      stroke: props.themeColor ? props.theme[props.themeColor] : "none",
    },
  },

  "& path": {
    stroke: props.basicColor === "black" ? "black" : props.theme.background2,
  },
}));
MbjBack.defaultProps = {
  themeColor: "primary",
  height: "100%",
  disabled: false,
  basicColor: "black",
};
