import {
  MbjButton,
  MbjDivider,
  MbjFlex,
  MbjHeading,
  MbjLoading,
  MbjNavPath,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjStaticInfo,
} from "@mbj-front-monorepo/ui";
import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  components,
  useGetCompetencesUserMetiers,
  useGetPropositionOffre,
  useMutatePropositionOffre,
} from "@mbj-front-monorepo/data-access";
import styled from "styled-components";
import { useForm } from "react-hook-form";

interface PropositionOffreDetailCtrlProps {
  className?: string;
}

interface ListActivite {
  niveauLib: string;
  activites: components["schemas"]["Competence-read.Competence"][];
}

const PropositionOffreDetailCtrl = (props: PropositionOffreDetailCtrlProps) => {
  const location = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const { idPropositionOffre } = useParams();
  const propositionOffreQuery = useGetPropositionOffre(idPropositionOffre ? parseInt(idPropositionOffre) : undefined);

  const mutatePropositionOffre = useMutatePropositionOffre(idPropositionOffre ? parseInt(idPropositionOffre) : -1);

  const UserMetierCompQuery = useGetCompetencesUserMetiers(
    propositionOffreQuery.data?.OffreInterne?.UserMetier
      ? propositionOffreQuery.data?.OffreInterne?.UserMetier.id
      : undefined
  );

  const ListActivite: ListActivite[] = useMemo(() => {
    if (UserMetierCompQuery.isLoading || UserMetierCompQuery.isError || !UserMetierCompQuery.data) {
      return [];
    } else {
      const TabListActivite: ListActivite[] = [];
      const TabNiveauComps = UserMetierCompQuery.data.filter((c) => c.Competence !== undefined);
      const TabNiveau5: components["schemas"]["Competence-read.Competence"][] = [];
      const TabNiveau3: components["schemas"]["Competence-read.Competence"][] = [];
      const TabNiveau1: components["schemas"]["Competence-read.Competence"][] = [];
      UserMetierCompQuery.data.map((item) => {
        if (item && item.Competence && item.Niveau && item.Niveau >= 4) {
          TabNiveau5.push(item.Competence);
        } else if (item && item.Competence && item.Niveau && item.Niveau >= 2) {
          TabNiveau3.push(item.Competence);
        } else if (item && item.Competence && item.Niveau) {
          TabNiveau1.push(item.Competence);
        }
        return item;
      });
      TabListActivite.push({
        niveauLib: "Expert",
        activites: TabNiveau5,
      });
      TabListActivite.push({
        niveauLib: "Maitrise",
        activites: TabNiveau3,
      });
      TabListActivite.push({
        niveauLib: "Connaissances",
        activites: TabNiveau1,
      });
      return TabListActivite;
    }
  }, [UserMetierCompQuery.isLoading, UserMetierCompQuery.isError, UserMetierCompQuery.data]);

  return (
    <div className={props.className}>
      {propositionOffreQuery.isLoading ? (
        <MbjLoading />
      ) : propositionOffreQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[
              { index: 1, label: "Proposition d'offre" },
              { index: 2, label: propositionOffreQuery.data.OffreInterne?.libelle },
            ]}
          />
          <MbjFlex direction={"column"}>
            <MbjHeading size={"md"}>
              {propositionOffreQuery.data.OffreInterne?.libelle} -{" "}
              {propositionOffreQuery.data.OffreInterne?.UserMetier?.Metier?.libelle}{" "}
            </MbjHeading>
            <MbjStaticInfo
              label={"Entreprise : "}
              info={
                propositionOffreQuery.data.OffreInterne?.Member
                  ? propositionOffreQuery.data.OffreInterne?.Member.name
                  : "inconnu"
              }
              direction={"row"}
              gap={"5px"}
            />
            <MbjStaticInfo
              label={"Code postal : "}
              info={
                propositionOffreQuery.data.OffreInterne?.codePostal
                  ? propositionOffreQuery.data.OffreInterne?.codePostal
                  : "inconnu"
              }
              direction={"row"}
              gap={"5px"}
            />
            <MbjStaticInfo
              label={"Description : "}
              info={
                propositionOffreQuery.data.OffreInterne?.description
                  ? propositionOffreQuery.data.OffreInterne?.description
                  : ""
              }
              direction={"row"}
              gap={"5px"}
            />
            <MbjDivider />
            <MbjHeading size={"sm"}>Compétences requises</MbjHeading>
            {ListActivite.map((item: ListActivite, idx) => (
              <MbjFlex direction={"column"} gap={"6px"} key={idx}>
                <div className={`niveau-connaissance`}>{item.niveauLib}</div>
                <div className={`list-comps`}>
                  <ul>
                    {item.activites.map((acti, idxA: number) => (
                      <li key={acti.id}>{acti.libelle}</li>
                    ))}
                  </ul>
                </div>
              </MbjFlex>
            ))}
            <MbjDivider />
            <MbjHeading size={"sm"}>Informations complémentaires</MbjHeading>
            <MbjStaticInfo
              label={"Commentaire du conseiller : "}
              info={
                propositionOffreQuery.data.commentairesConseiller
                  ? propositionOffreQuery.data.commentairesConseiller
                  : "inconnu"
              }
              direction={"row"}
              gap={"5px"}
            />
            <MbjHeading size={"sm"}>Répondre à la proposition</MbjHeading>
            <form
              onSubmit={handleSubmit((data) => {
                console.log(data);
                mutatePropositionOffre.mutate(data);
              })}
            >
              <MbjFlex gap={"20px"}>
                <MbjSimpleInputContainer errors={errors} label={"Niveau d'intérêt (0 - 5)"} direction={"column"}>
                  <MbjSimpleInput
                    name={"niveauInteret"}
                    id={"niveauInteret"}
                    type={"number"}
                    noPadding
                    register={register}
                    defaultValue={5}
                    registerOptions={{
                      required: "Ce champ est obligatoire",
                      min: {
                        value: 0,
                        message: "Ne peut pas être inférieur à 0",
                      },
                      max: {
                        value: 5,
                        message: "Ne peut pas être supérieur à 5",
                      },
                      valueAsNumber: true,
                    }}
                  />
                </MbjSimpleInputContainer>
                <MbjButton isPending={mutatePropositionOffre.isLoading}>Répondre</MbjButton>
              </MbjFlex>
            </form>
          </MbjFlex>
        </>
      )}
    </div>
  );
};

export const PropositionOffreDetail = styled(PropositionOffreDetailCtrl)((props) => ({
  padding: "1% 2%",
  display: "flex !important",
  flexDirection: "column",
  height: "100%",

  ".niveau-connaissance": {
    color: props.theme.primaryLighten,
    fontWeight: "bold",
    marginBottom: "7px",
  },
  ".list-comps": {
    paddingLeft: "30px",
  },
}));
