import React from "react";
import styled from "styled-components";

interface MbjRankCtrlProps {
  /**
   * Classe HTML :
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Note maximale du rang
   */
  maxRank?: number;
  /**
   * Rank
   */
  rank?: number;
  /**
   * icone étoile ?
   */
  isStar?: boolean;
}

/**
 * Retourne un tableau d'entier représentant le rang
 *
 * @param maxRank
 * @param rank
 */
function notationsTab(maxRank: number = 5, rank: number = 0): number[] {
  const rankTabs = [];
  for (let i = 0; i < maxRank; i++) {
    if (i < rank) {
      rankTabs.push(1);
    } else {
      rankTabs.push(0);
    }
  }
  return rankTabs;
}
function MbjRankCtrl(props: MbjRankCtrlProps) {
  const rankTabs = notationsTab(props.maxRank, props.rank);

  return (
    <div className={props.className}>
      {rankTabs.map((rankItem, i) =>
        props.isStar ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            key={i}
            className={rankItem === 0 ? "rankItem empty" : "rankItem fill"}
            viewBox="0 0 62.061 59.341"
          >
            <path d="M50.694 58.72c-2.252 1.685-16.49-9.818-19.303-9.78-2.812.04-16.728 11.929-19.026 10.307-2.299-1.622 4.242-18.717 3.336-21.38C14.795 35.203-.813 25.643.019 22.956c.833-2.687 19.112-1.75 21.365-3.434C23.637 17.837 27.906.039 30.719 0c2.813-.039 7.57 17.636 9.868 19.258 2.298 1.622 20.545.182 21.451 2.846.906 2.663-14.433 12.649-15.266 15.336-.832 2.687 6.175 19.596 3.922 21.28z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            key={i}
            className={rankItem === 0 ? "rankItem empty" : "rankItem fill"}
            viewBox="0 0 58 58"
          >
            <circle cx="42.922" cy="43.266" r="29" transform="translate(-13.922 -14.266)" />
          </svg>
        )
      )}
    </div>
  );
}

/**
 * Composant contenant une animation de "chargement"
 */
export const MbjRank = styled(MbjRankCtrl)((props) => ({
  display: "flex",
  flexDirection: "row",
  gap: "0.2em",

  "& .rankItem": {
    height: "1em",
    aspectRatio: "1 / 1",
    borderRadius: "100%",
  },
  "& .empty": {
    fill:
      props.themeColor === "primary"
        ? props.theme.primaryPastel
        : props.themeColor === "secondary"
        ? props.theme.secondaryPastel
        : props.themeColor === "third"
        ? props.theme.thirdPastel
        : props.themeColor === "complementary"
        ? props.theme.complementaryPastel
        : "none",
    opacity: 0.5,
  },
  "& .fill": {
    fill:
      props.themeColor === "primary"
        ? props.theme.primary
        : props.themeColor === "secondary"
        ? props.theme.secondary
        : props.themeColor === "third"
        ? props.theme.third
        : props.themeColor === "complementary"
        ? props.theme.complementary
        : "none",
  },
}));
MbjRank.defaultProps = {
  themeColor: "primary",
  maxRank: 5,
  rank: 0,
};
