import React from "react";
import styled from "styled-components";
import { useGetOnePartMob, useGetParticulierMe } from "@mbj-front-monorepo/data-access";
import { useLocation, useParams } from "react-router-dom";
import { MbjFlex, MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import { MbjCompareMobilites, MbjCompareReferentiel } from "@mbj-front-monorepo/data-ui";

interface MobiliteDetailsCtrlProps {
  className?: string;
}

const MobiliteDetailsCtrl = (props: MobiliteDetailsCtrlProps) => {
  const location = useLocation();
  const { idMobPart } = useParams();
  if (!idMobPart) {
    throw new Error("id or idMetier is undefined");
  }

  const particulierQuery = useGetParticulierMe();
  const particulierMobQuery = useGetOnePartMob(idMobPart ? parseInt(idMobPart) : undefined, particulierQuery.data?.id);

  return (
    <div className={props.className}>
      {particulierQuery.isLoading || particulierMobQuery.isLoading ? (
        <MbjLoading />
      ) : particulierQuery.isError || particulierMobQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
          <MbjNavPath
            routePath={location.pathname}
            changeLabelTab={[{ index: 2, label: particulierMobQuery.data?.Metier?.libelle?.toUpperCase() }]}
          />
          <MbjFlex direction={"column"} basis={"100%"}>
            <h1>Mobilité vers : {particulierMobQuery.data?.Metier?.libelle}</h1>
            <MbjCompareReferentiel
              idToCompare1={particulierQuery.data.id || -1}
              idToCompare2={particulierMobQuery.data?.Metier?.id || -1}
              typeCompared={"PM"}
            />
            {particulierMobQuery.data && particulierMobQuery.data.Metier && (
              <MbjCompareMobilites Metier={particulierMobQuery.data.Metier} idParticulier={particulierQuery.data.id} />
            )}
            {/*<FormationsToWidget employeeId={employeeQuery.data.id} metierId={metierQuery.data.id} />*/}
          </MbjFlex>
        </>
      )}
    </div>
  );
};

export const MobiliteDetails = styled(MobiliteDetailsCtrl)((props) => ({
  padding: "1% 2%",
  h1: {
    fontSize: "24px",
    paddingLeft: "0.5rem",
    margin: "15px auto 20px 0",
  },
}));
