import styled from 'styled-components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { TooltipArrowProps } from '@radix-ui/react-tooltip';

interface MbjTooltipArrowProps {}

export const MbjTooltipArrow = styled(TooltipPrimitive.Arrow)<MbjTooltipArrowProps & TooltipArrowProps>((props) => ({
  fill: props.theme.background2,
}));
MbjTooltipArrow.defaultProps = {};
