import * as TabsPrimitive from "@radix-ui/react-tabs";
import { TabsTriggerProps } from "@radix-ui/react-tabs";
import styled from "styled-components";
import * as React from "react";

interface MbjTabsTriggerProps extends TabsTriggerProps {
  className?: string;

  children?: React.ReactNode;
}

const MbjTabsTriggerCtrl = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>((props, forwardedRef) => {
  return (
    <TabsPrimitive.Trigger {...props} ref={forwardedRef}>
      {props.children}
    </TabsPrimitive.Trigger>
  );
});

export const MbjTabsTrigger = styled(MbjTabsTriggerCtrl)<MbjTabsTriggerProps>((props) => ({
  all: "unset",
  fontFamily: "inherit",
  backgroundColor: props.theme.background2,
  padding: "0 20px",
  height: 45,
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "calc(9px + 0.9vmin)",
  cursor: "pointer",
  fontWeight: 600,
  lineHeight: 1,
  color: "black",
  userSelect: "none",
  '&[data-state="active"]': {
    backgroundColor: props.theme.primaryLighten,
  },
  "&:active": { backgroundColor: props.theme.primaryPastel },

  "&:focus-visible": { outline: "2px solid blue" },
}));
MbjTabsTrigger.defaultProps = {};
