import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface MbjTableCheckboxCtrlProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;

  indeterminate?: boolean;
}

export const MbjTableCheckboxCtrl = ({ className, indeterminate, ...rest }: MbjTableCheckboxCtrlProps) => {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} className={className + " cursor-pointer"} {...rest} />;
};

export const MbjTableCheckbox = styled(MbjTableCheckboxCtrl)((props) => ({}));

MbjTableCheckbox.defaultProps = {};
