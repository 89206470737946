import * as React from "react";
import styled, { useTheme } from "styled-components";
import { BiMenu } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { MbjLink } from "../../../atoms/MbjLink/MbjLink";
import { useNavigate } from "react-router-dom";

interface MbjHeadBarMobileCtrlProps {
  /**
   * Classe HTML de la HeadBar ?
   */
  className?: string;
  /**
   * Hauteur de la headbar
   */
  height?: string;
  /**
   * Largeur de la headbar
   */
  width?: string;
  /**
   * fonction à appeler lors de la déconnexion :
   */
  disonnectionCallback?: () => void;
}

function MbjHeadBarMobileCtrl(props: MbjHeadBarMobileCtrlProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isFold, setIsFold] = React.useState(true);

  function foldMenu() {
    setIsFold(!isFold);
  }
  function handleDeconnexion(e?: React.MouseEvent<HTMLDivElement>) {
    console.log("deconnect");
    //navigate("/login");
    if (props.disonnectionCallback) {
      props.disonnectionCallback();
      navigate("/login");
    }
  }

  return (
    <div className={props.className}>
      <div className={"bar"}>
        <img src={theme.logo} alt="logo mbj" className={"logo "} />
        <div className={"toggleMiniMenu"} onClick={() => foldMenu()}>
          {isFold ? <BiMenu size={"45px"} /> : <IoClose size={"45px"} />}
        </div>
      </div>
      {!isFold && (
        <div className={"miniMenu"}>
          <MbjLink themeColor="white" isNavLink to="mentions">
            Mentions légales
          </MbjLink>
          <MbjLink themeColor="white" isNavLink onClick={handleDeconnexion}>
            Déconnexion
          </MbjLink>
        </div>
      )}
    </div>
  );
}

export const MbjHeadBarMobile = styled(MbjHeadBarMobileCtrl)((props) => ({
  background: props.theme.primary,

  ".bar": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "1.2vw",
    paddingRight: "2.5vw",
    height: props.height,
    width: props.width,
    padding: "0 2.5vw",
  },

  ".logo": {
    height: "50%",
  },
  ".toggleMiniMenu": {
    color: props.theme.background2,
    cursor: "pointer",

    "&:hover": {
      color: props.theme.secondary,
    },
  },
  ".miniMenu": {
    backgroundColor: props.theme.primaryDarken,
    display: "flex",
    flexDirection: "column",
    padding: "0 2.5vw",

    "*:first-child": {
      borderTop: "none",
    },
    "& > *": {
      display: "inline",
      //verticalAlign: "middle",
      borderTop: "1px solid " + props.theme.lightText,
      fontSize: "calc(11px + 0.85vmin)",
      fontWeight: 600,
      padding: "5px 0",
    },
  },
}));
MbjHeadBarMobile.defaultProps = {
  width: "100vw",
  height: "7vh",
};
