import React from "react";
import styled from "styled-components";
import { Operation, useGetTypesContracts, useMutateXpPro } from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import {
  CustomSelect,
  MbjButton,
  MbjHeading,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import { getDateEN, GetElementType } from "@mbj-front-monorepo/utils";

interface MbjEmployeeXpEditFormCtrlProps {
  className?: string;
  EmployeeXp: GetElementType<Operation<"getEmployeeXpCollection">>;
  hideModal: () => void;
}

const MbjEmployeeXpEditFormCtrl = (props: MbjEmployeeXpEditFormCtrlProps) => {
  const typeContractsQuery = useGetTypesContracts();
  const mutation = useMutateXpPro(props.EmployeeXp.id || -1);

  const defaultValues = {
    startAt: props.EmployeeXp.startAt ? getDateEN(new Date(props.EmployeeXp.startAt)) : "",
    endAt: props.EmployeeXp.endAt ? getDateEN(new Date(props.EmployeeXp.endAt)) : "",
    TypeContrat: props.EmployeeXp.TypeContrat?.id ? props.EmployeeXp.TypeContrat?.id.toString() : "",
    salary: props.EmployeeXp.salary,
    description: props.EmployeeXp.description,
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<Operation<"putEmployeeXpItem", "requestBody">>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const datas = {
          ...data,
          TypeContrat: `/api/type_contrats/${data.TypeContrat}`,
          endAt: data.endAt ? data.endAt : undefined,
        };

        mutation.mutate(datas, {
          onSettled: () => {
            if (props.hideModal !== undefined) {
              props.hideModal();
            }
          },
        });
      })}
      className={props.className}
    >
      <MbjHeading as={"h2"}>Expérience en cours de modification : {props.EmployeeXp.Metier?.libelle}</MbjHeading>
      <MbjSimpleInputContainer errors={errors} label={"Date de début *"}>
        <MbjSimpleInput
          name={"startAt"}
          id={"startAt"}
          type={"date"}
          noPadding
          register={register}
          registerOptions={{
            required: {
              value: true,
              message: "Date d'obtention requise",
            },
          }}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Date de fin"}>
        <MbjSimpleInput name={"endAt"} id={"endAt"} type={"date"} noPadding register={register} />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Type du contrat *"} htmlFor={"idContrat"}>
        <Controller
          name={"TypeContrat"}
          control={control}
          rules={{ required: { value: true, message: "Contrat requis" } }}
          render={({ field }) => (
            <CustomSelect
              inputId={"TypeContrat"}
              isLoading={typeContractsQuery.isLoading}
              options={typeContractsQuery.data}
              getOptionLabel={(option) => option?.libelle || ""}
              getOptionValue={(option) => option?.id + ""}
              isClearable={true}
              defaultValue={props.EmployeeXp.TypeContrat}
              onChange={(selectedOption, triggeredAction) => {
                if (selectedOption) {
                  field.onChange(selectedOption.id);
                }
                if (triggeredAction.action === "clear") {
                  field.onChange(null);
                }
              }}
            />
          )}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Salaire"}>
        <MbjSimpleInput
          name={"salary"}
          id={"salary"}
          type={"number"}
          noPadding
          register={register}
          registerOptions={{
            min: {
              value: 0,
              message: "Ne peut pas être négatif",
            },
            valueAsNumber: true,
          }}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"description"}>
        <MbjSimpleTextArea name={`description`} id={`description`} noPadding register={register} />
      </MbjSimpleInputContainer>
      <MbjButton isPending={mutation.isLoading}>Confirmer</MbjButton>
    </form>
  );
};

export const MbjEmployeeXpEditForm = styled(MbjEmployeeXpEditFormCtrl)((props) => ({
  gap: "8px",
  display: "flex",
  flexDirection: "column",

  button: {
    alignSelf: "flex-end",
  },
}));
