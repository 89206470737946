import * as React from "react";
import { Children } from "react";
import styled from "styled-components";

interface MbjAspectRatioCtrlProps {
  /**
   * Classe HTML de l'élément
   */
  className?: string;
  /**
   * Contenu de l'élément
   */
  children?: React.ReactNode;

  ratio?: number;
}

function MbjAspectRatioCtrl(props: MbjAspectRatioCtrlProps) {
  const child = Children.only(props.children);

  return <div className={props.className}>{child}</div>;
}

export const MbjAspectRatio = styled(MbjAspectRatioCtrl)((props) => ({
  position: "relative",
  "&::before": {
    height: 0,
    content: '""',
    display: "block",
    paddingBottom: `${(1 / (props.ratio || 1)) * 100}%`,
  },
  "& > *": {
    overflow: "hidden",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));
MbjAspectRatio.defaultProps = {
  ratio: 4 / 3,
};
