import * as React from "react";
import styled from "styled-components";
import { MbjSimpleInput, MbjSimpleInputCtrlProps } from "../MbjSimpleInput";
import { FieldValues } from "react-hook-form";

interface MbjEmailSimpleInputCtrlProps<TFormValues extends FieldValues> extends MbjSimpleInputCtrlProps<TFormValues> {}

function MbjEmailSimpleInputCtrl<TFormValues extends FieldValues>(props: MbjEmailSimpleInputCtrlProps<TFormValues>) {
  const { className, themeColor, register, setValue, trigger, registerOptions, ...rest } = props;

  return (
    <MbjSimpleInput
      type="email"
      placeholder={"exemple@mail.com"}
      className={props.className}
      register={register}
      registerOptions={{
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Veuillez entrer une adresse mail valide",
        },
        ...registerOptions,
      }}
      {...rest}
    />
  );
}

/**
 * Représente un input :
 */
export const MbjEmailSimpleInput = styled(MbjEmailSimpleInputCtrl)((props) => ({}));

MbjEmailSimpleInput.defaultProps = {
  themeColor: "primary",
  type: "text",
  noPadding: false,
};
