import * as React from "react";
import styled from "styled-components";
import { FlexContainerOptions, FlexItemOptions, useFlexContainer, useFlexItem } from "../../flex-utils";

export interface MbjWidgetCtrlProps extends FlexContainerOptions, FlexItemOptions {
  /**
   * Classe HTML de l'élément?
   */
  className?: string;
  /**
   * Enfants de l'élément?
   */
  children?: React.ReactNode;
}

function MbjWidgetCtrl(props: MbjWidgetCtrlProps) {
  return <div className={props.className}>{props.children}</div>;
}

export const MbjWidget = styled(MbjWidgetCtrl)((props) => ({
  display: "flex",

  "& > .MbjWidgetHeader": {
    marginTop: "10px",
  },

  "& > .MbjWidgetBody": {
    //flexGrow: 1,
  },

  ...useFlexContainer(props),
  ...useFlexItem(props),
}));
MbjWidget.defaultProps = {
  direction: "column",
  gap: "10px",
};
