import * as React from "react";
import styled from "styled-components";

export interface MbjCardHeaderCtrlProps {
  /**
   * Classe HTML de la card?
   */
  className?: string;
  /**
   * Contenu de la card ?
   */
  children?: React.ReactNode;
}

function MbjCardHeaderCtrl(props: MbjCardHeaderCtrlProps) {
  return <div className={`MbjCardHeader ${props.className}`}>{props.children}</div>;
}
/**
 * Composant layout card :
 */
export const MbjCardHeader = styled(MbjCardHeaderCtrl)<MbjCardHeaderCtrlProps>((props) => ({}));
MbjCardHeader.defaultProps = {};
