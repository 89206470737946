import * as React from "react";

import { MbjButton, MbjEmailSimpleInput, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useForm } from "react-hook-form";

interface ForgotPasswordFormCtrlProps {
  className?: string;
}

function ForgotPasswordFormCtrl(props: ForgotPasswordFormCtrlProps) {
  const onSubmit = (data: any) => console.log(data);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"ForgotPasswordForm " + props.className}>
      <MbjSimpleInputContainer errors={errors} label={"Email"}>
        <MbjEmailSimpleInput
          name={"email"}
          id={"email"}
          noPadding
          register={register}
          registerOptions={{
            required: {
              value: true,
              message: "Email requis",
            },
          }}
        />
      </MbjSimpleInputContainer>
      <MbjButton themeColor="primary">Envoyer</MbjButton>
    </form>
  );
}
export const ForgotPasswordForm = styled(ForgotPasswordFormCtrl)((props) => ({
  p: {
    fontSize: "18px",
  },
  button: {
    width: "30%",
    minWidth: "170px",
    marginTop: "20px",
  },
}));
