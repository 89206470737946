function decodeJwt(token: string) {
  const base64Payload = token.split(".")[1];
  const payloadBuffer = Buffer.from(base64Payload, "base64");
  return JSON.parse(payloadBuffer.toString());
}

export function isAuth(): boolean {
  const token = localStorage.getItem("__user_token__");
  if (token) {
    try {
      const decodedToken = decodeJwt(token);
      if (decodedToken.exp > Date.now() / 1000) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }
  return false;
}
