import { useLocation } from "react-router-dom";
import styled from "styled-components";

interface NotFoundProps {}

function NotFoundCtrl(props: NotFoundProps) {
  const location = useLocation();
  return <p>Page not found for {location.pathname} !</p>;
}
export const NotFound = styled(NotFoundCtrl)((props) => ({}));
