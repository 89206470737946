import React from 'react';
import styled from 'styled-components';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { SwitchThumbProps } from '@radix-ui/react-switch';

interface MbjSwitchThumbProps {}

/**
 * A control that allows the user to toggle between checked and not checked.
 * https://www.radix-ui.com/docs/primitives/components/switch#root
 */
export const MbjSwitchThumb = styled(SwitchPrimitive.Thumb)<MbjSwitchThumbProps & SwitchThumbProps>((props) => ({
  display: 'block',
  height: '80%',
  aspectRatio: '1 / 1 !important',
  backgroundColor: props.theme.primaryPastel,
  borderRadius: '9999px',
  //boxShadow: `0 2px 2px ${props.theme.dark}`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(21px)', backgroundColor: props.theme.primaryDarken },
}));

MbjSwitchThumb.defaultProps = {};
