import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  MbjButton,
  MbjFlex,
  MbjModal,
  MbjNavPath,
  MbjTabs,
  MbjTabsContent,
  MbjTabsList,
  MbjTabsTrigger,
  useModal,
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { useAddParticulierActivite, useGetParticulierMe } from "@mbj-front-monorepo/data-access";
import { MbjFindActivite, MbjParticulierActiviteAccordion } from "@mbj-front-monorepo/data-ui";
import { CV } from "./Cv";
import { GoPlus } from "react-icons/go";

interface CV_CompetencesCtrlProps {
  className?: string;
}

function CV_CompetencesCtrl(props: CV_CompetencesCtrlProps) {
  const location = useLocation();
  const { isShowing: isShowingFindCompetence, toggle: toggleFindCompetence } = useModal();

  const particulierQuery = useGetParticulierMe();

  const mutationAdd = useAddParticulierActivite(particulierQuery.data?.id || -1);

  const HandleEndFindActi = (data: { idActivite: number; niveau: number }) => {
    mutationAdd.mutate(
      {
        Particulier: "/api/particuliers/" + particulierQuery.data?.id,
        niveau: data.niveau,
        Activite: "/api/activites/" + data.idActivite,
      },
      {
        onSuccess: () => {
          toggleFindCompetence();
        },
      }
    );
  };

  return (
    <div className={props.className}>
      <MbjNavPath routePath={location.pathname} />
      <MbjTabs defaultValue="tab1" className={"tabs"}>
        <MbjTabsList aria-label="Mobilités" isFitted>
          <MbjTabsTrigger value="tab1">Mon CV</MbjTabsTrigger>
          <MbjTabsTrigger value="tab2">Mes compétences</MbjTabsTrigger>
        </MbjTabsList>
        <MbjTabsContent value="tab1" $noPadding>
          <CV />
        </MbjTabsContent>
        <MbjTabsContent value="tab2" $noPadding>
          <MbjFlex direction={"column"} gap={"12px"} className={"competencesTab"}>
            <MbjButton leftIcon={<GoPlus />} onClick={toggleFindCompetence} alignSelf={"flex-end"}>
              Ajouter une compétence
            </MbjButton>
            <MbjParticulierActiviteAccordion particulierId={particulierQuery.data?.id || -1} themeColor={"grey"} />
          </MbjFlex>
        </MbjTabsContent>
      </MbjTabs>
      <MbjModal hide={toggleFindCompetence} isShowing={isShowingFindCompetence} title="Ajout d'une compétence">
        <MbjFindActivite CallBackFx={HandleEndFindActi} isPending={mutationAdd.isLoading} />
      </MbjModal>
    </div>
  );
}
export const CV_Competences = styled(CV_CompetencesCtrl)((props) => ({
  padding: "1% 2%",
  overflowY: "auto",
  ".competencesTab": {
    height: "100%",
    paddingTop: "6px",
  },
}));
