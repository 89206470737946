import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { RadioGroupIndicatorProps } from '@radix-ui/react-radio-group';
import styled from 'styled-components';

interface MbjRadioGroupIndicatorProps {}

export const MbjRadioGroupIndicator = styled(RadioGroupPrimitive.Indicator)<
  MbjRadioGroupIndicatorProps & RadioGroupIndicatorProps
>((props) => ({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    width: '50%',
    height: '50%',
    borderRadius: '100%',
    backgroundColor: props.theme.primaryDarken,
  },
}));

MbjRadioGroupIndicator.defaultProps = {};
