import React, { useMemo } from "react";
import styled from "styled-components";
import { components, Operation, useGetCompetencesUserMetiers } from "@mbj-front-monorepo/data-access";
import { MbjFlex, MbjScrollArea } from "@mbj-front-monorepo/ui";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface MbjOffreInterneItemCtrlProps {
  className?: string;
  OffreInterne: GetElementType<Operation<"getOffreInterneCollection">>;

  callToAction?: React.ReactNode;
}

interface ListActivite {
  niveauLib: string;
  activites: components["schemas"]["Competence-read.Competence"][];
}

const MbjOffreInterneItemCtrl = (props: MbjOffreInterneItemCtrlProps) => {
  const UserMetierCompQuery = useGetCompetencesUserMetiers(
    props.OffreInterne.UserMetier ? props.OffreInterne.UserMetier.id : undefined
  );
  const ListActivite: ListActivite[] = useMemo(() => {
    if (UserMetierCompQuery.isLoading || UserMetierCompQuery.isError || !UserMetierCompQuery.data) {
      return [];
    } else {
      const TabListActivite: ListActivite[] = [];
      const TabNiveauComps = UserMetierCompQuery.data.filter((c) => c.Competence !== undefined);
      const TabNiveau5: components["schemas"]["Competence-read.Competence"][] = [];
      const TabNiveau3: components["schemas"]["Competence-read.Competence"][] = [];
      const TabNiveau1: components["schemas"]["Competence-read.Competence"][] = [];
      UserMetierCompQuery.data.map((item) => {
        if (item && item.Competence && item.Niveau && item.Niveau >= 4) {
          TabNiveau5.push(item.Competence);
        } else if (item && item.Competence && item.Niveau && item.Niveau >= 2) {
          TabNiveau3.push(item.Competence);
        } else if (item && item.Competence && item.Niveau) {
          TabNiveau1.push(item.Competence);
        }
        return item;
      });
      TabListActivite.push({
        niveauLib: "Expert",
        activites: TabNiveau5,
      });
      TabListActivite.push({
        niveauLib: "Maitrise",
        activites: TabNiveau3,
      });
      TabListActivite.push({
        niveauLib: "Connaissances",
        activites: TabNiveau1,
      });
      return TabListActivite;
    }
  }, [UserMetierCompQuery.isLoading, UserMetierCompQuery.isError, UserMetierCompQuery.data]);
  return (
    <MbjFlex className={props.className} wrap={"nowrap"}>
      <div className={`logo-place`}>
        <img src={`${process.env["REACT_APP_API_URL_ROOT"]}${props.OffreInterne.Member?.logoUrl}`} alt={"logo"} />
      </div>
      <MbjFlex grow={1} direction={"column"} gap={"10px"}>
        <MbjFlex justify={"space-between"} className={"header"}>
          <div>
            {props.OffreInterne.libelle} - {props.OffreInterne.UserMetier?.Metier?.libelle}
          </div>
          <div>{props.OffreInterne.score ? `${Math.round(props.OffreInterne.score * 10000) / 100}%` : ""}</div>
        </MbjFlex>
        <div className={"entreprise-place"}>
          {props.OffreInterne.Member ? props.OffreInterne.Member.name : "inconnue"}
        </div>
        <div className={`location-lib`}>{props.OffreInterne.codePostal ? props.OffreInterne.codePostal : ""}</div>
        <MbjScrollArea maxHeight={"150px"}>
          <MbjFlex direction={"column"} gap={"15px"}>
            {ListActivite.map((item: ListActivite, idx) => (
              <MbjFlex direction={"column"} gap={"6px"} key={idx}>
                <div className={`niveau-connaissance`}>{item.niveauLib}</div>
                <div className={`list-comps`}>
                  <ul>
                    {item.activites.map((acti, idxA: number) => (
                      <li key={acti.id}>{acti.libelle}</li>
                    ))}
                  </ul>
                </div>
              </MbjFlex>
            ))}
          </MbjFlex>
        </MbjScrollArea>
        <MbjFlex justify={"space-between"} align={"flex-end"}>
          <div className={`description-place`}>
            {props.OffreInterne.description ? props.OffreInterne.description : ""}
          </div>
          {props.callToAction}
        </MbjFlex>
      </MbjFlex>
    </MbjFlex>
  );
};

export const MbjOffreInterneItem = styled(MbjOffreInterneItemCtrl)((props) => ({
  borderBottom: `${props.theme.primaryLighten} solid 1px`,
  padding: "5px",

  ".header": {
    fontWeight: "bold",
  },

  ".entreprise-place": {
    color: props.theme.complementaryLighten,
    fontWeight: "bold",
  },
  ".location-lib": {
    color: props.theme.complementaryDarken,
    fontWeight: "bold",
  },

  ".niveau-connaissance": {
    fontWeight: "bold",
    textDecoration: "underline",
    marginBottom: "7px",
  },
  ".list-comps": {
    paddingLeft: "30px",
  },

  ".logo-place": {
    padding: "0.5rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    img: {
      width: "80px",
      height: "auto",
    },
  },
}));
