import * as React from "react";
import styled from "styled-components";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { MbjLink } from "../../atoms/MbjLink/MbjLink";

interface ChangeLabelProps {
  index: number;
  label?: string | undefined;
}

interface MbjNavPathCtrlProps {
  /**
   * Classe HTML du NavPath ?
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du NavPath sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Route actuelle
   */
  routePath?: string;
  /**
   * Margin ?
   */
  noMargin?: boolean;
  /**
   *Personnalisation d'un label du navpath ?
   */
  changeLabel?: ChangeLabelProps;
  changeLabelTab?: ChangeLabelProps[] | null;
}

function MbjNavPathCtrl(props: MbjNavPathCtrlProps) {
  if (!props.routePath) {
    return <React.Fragment />;
  }
  //On sépare la route :
  const routeArray = props.routePath.split("/");
  //On supprime le premier élément (qui correspond à "")
  routeArray.shift();

  function getRoute(arr: Array<string>, index: number): string | Error {
    if (index >= arr.length) {
      return new Error("Index trop grand");
    }
    const arrCopie = [...arr];
    arrCopie.splice(index + 1);
    return arrCopie.join("/");
  }

  return (
    <div className={props.className}>
      {routeArray.map((element: any, i: number) => {
        let Changer: ChangeLabelProps[] = [];
        if (props.changeLabelTab) {
          Changer = props.changeLabelTab?.filter((ch) => ch.index === i);
        } else if (props.changeLabel && props.changeLabel.index === i) {
          Changer = [props.changeLabel];
        }
        const elementUpFirst =
          Changer?.length === 0
            ? element.charAt(0).toUpperCase() + element.slice(1)
            : Changer !== undefined
            ? Changer[0].label
            : "";

        const routeOfElement = "/" + getRoute(routeArray, i);
        const className = routeOfElement === props.routePath ? "active" : "";

        if (element !== "") {
          return (
            <React.Fragment key={i}>
              <MbjLink themeColor={props.themeColor} to={routeOfElement} className={className}>
                {i === 0 ? "Accueil" : elementUpFirst}
              </MbjLink>
              {i !== routeArray.length - 1 ? (
                <span className={"iconContainer"}>
                  <IoChevronForwardCircleOutline />
                </span>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        }
        return <React.Fragment key={i} />;
      })}
    </div>
  );
}

export const MbjNavPath = styled(MbjNavPathCtrl)((props) => ({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: !props.noMargin ? "15px" : "0px",
  height: "fit-content",
  ".iconContainer": {
    display: "flex",
    alignItems: "center",
  },
  "& > *": {
    color:
      props.themeColor === "primary"
        ? props.theme.primaryPastel
        : props.themeColor === "secondary"
        ? props.theme.secondaryPastel
        : props.themeColor === "third"
        ? props.theme.thirdPastel
        : props.themeColor === "complementary"
        ? props.theme.complementaryPastel
        : "none",
  },
  a: {
    padding: "0px 7px",
    fontSize: "calc(8px + 0.8vmin)",
  },
  "& a:first-of-type": {
    paddingLeft: "0",
  },
  "& a:last-of-type": {
    paddingRight: "0",
  },
}));
MbjNavPath.defaultProps = {
  themeColor: "primary",
  noMargin: false,
};
