import * as TabsPrimitive from "@radix-ui/react-tabs";
import styled from "styled-components";
import * as React from "react";

interface MbjTabsContentProps {
  $noPadding?: boolean;
}
export const MbjTabsContent = styled(TabsPrimitive.Content)<MbjTabsContentProps>((props) => ({
  flexGrow: 1,
  padding: !props.$noPadding ? "20px" : "0px",
  backgroundColor: props.theme.background2,
  borderBottomLeftRadius: "0.4em",
  borderBottomRightRadius: "0.4em",
  outline: "none",
  "&:focus-visible": { outline: "2px solid blue" },
}));
MbjTabsContent.defaultProps = {};
