import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { RadioGroupItemProps } from '@radix-ui/react-radio-group';
import styled from 'styled-components';

interface MbjRadioGroupItemProps {}

export const MbjRadioGroupItem = styled(RadioGroupPrimitive.Item)<MbjRadioGroupItemProps & RadioGroupItemProps>(
  (props) => ({
    all: 'unset',
    backgroundColor: 'white',
    width: '25px',
    height: '25px',
    borderRadius: '100%',
    boxShadow: `0 1px 3.5px ${props.theme.dark}`,
    '&:hover': { backgroundColor: props.theme.primaryPastel },
    '&:focus': { boxShadow: `0 0 0 2px ${props.theme.primaryDarken}` },
  })
);

MbjRadioGroupItem.defaultProps = {};
