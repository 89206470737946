import * as React from 'react';
import { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { RegisterOptions } from 'react-hook-form';

export interface MbjSimpleTextAreaCtrlProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Classe HTML du conteneur de l'input
   */
  className?: string;
  /**
   * Identifiant HTML du champ d'input
   */
  id?: string;

  /**
   * Couleur utilisée pour représenter l'ordre d'importance du texte area :
   */
  themeColor?: 'primary';
  /**
   * Nom de l'attribut correspondant à l'input dans l'objet data (lors de la soumission du formulaire)
   */
  name: string;
  /**
   * doit-il y avoir des marges internes ?
   */
  noPadding?: boolean;
  /**
   *resize
   */
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';

  register: any;
  setValue?: any;
  trigger?: any;

  registerOptions?: RegisterOptions;
  height?:string;
}

function MbjSimpleTextAreaCtrl(props: MbjSimpleTextAreaCtrlProps) {
  const { className, themeColor, register, name, noPadding, setValue, trigger, registerOptions, ...rest } = props;

  return <textarea className={props.className} {...props.register(props.name, registerOptions)} {...rest} />;
}

/**
 * Représente une aire de texte :
 */
export const MbjSimpleTextArea = styled(MbjSimpleTextAreaCtrl)((props) => ({
  padding: '0.3rem',
  border: 'solid #ccc 1px',
  borderRadius: '0.25rem',
  height:props.height ? props.height : "auto",
  resize: props.resize,
}));

MbjSimpleTextArea.defaultProps = {
  themeColor: 'primary',
  noPadding: false,
  resize: 'vertical',
};
