export function pad(number: number, length: number): string {
  let str = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
}
export function getDateTimeFR(date: Date): string {
  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  const hh = pad(date.getHours(), 2);
  const mm = pad(date.getMinutes(), 2);
  const ss = pad(date.getSeconds(), 2);
  return `${dd}/${MM}/${yyyy} à ${hh}:${mm}:${ss}`;
}

export function getDateFR(date: Date) {
  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  return `${dd}/${MM}/${yyyy}`;
}

export function getDateEN(date: Date): string {
  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  return `${yyyy}-${MM}-${dd}`;
}
export function getDateTimeEN(date: Date) {
  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  const hh = pad(date.getHours(), 2);
  const mm = pad(date.getMinutes(), 2);
  const ss = pad(date.getSeconds(), 2);
  return `${yyyy}-${MM}-${dd}-${hh}-${mm}-${ss}`;
}
