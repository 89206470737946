import { useQueryClient } from "@tanstack/react-query";

export function logout(queryClient?: ReturnType<typeof useQueryClient>) {
  console.log("useLoggedOut");
  localStorage.removeItem("__user_token__");
  if (queryClient) {
    queryClient.clear();
    console.log("datas cleared");
  } else {
    console.log("datas not cleared");
  }
}
