import Select, { createFilter, GroupBase, Props } from 'react-select';
import { CustomOption } from './CustomOption';

export function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  return (
    <Select
      {...props}
      components={{ Option: CustomOption }}
      classNamePrefix="custom-select"
      filterOption={createFilter({ ignoreAccents: false })}
      //theme={(theme) => ({ ...theme, borderRadius: 0 })}
    />
  );
}
