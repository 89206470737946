import * as React from "react";
import { useState } from "react";
import { MbjSimpleInput, MbjSimpleInputCtrlProps } from "../MbjSimpleInput";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MbjIconButton } from "../../MbjButton/MbjIconButton";
import styled from "styled-components";
import { FieldValues } from "react-hook-form";

interface MbjPasswordSimpleInputCtrlProps<TFormValues extends FieldValues>
  extends MbjSimpleInputCtrlProps<TFormValues> {}

function MbjPasswordSimpleInputCtrl<TFormValues extends FieldValues>(
  props: MbjPasswordSimpleInputCtrlProps<TFormValues>
) {
  const { className, themeColor, register, setValue, trigger, registerOptions, ...rest } = props;

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className={props.className}>
      <MbjSimpleInput
        type={passwordShown ? "text" : "password"}
        register={register}
        registerOptions={{
          required: {
            value: true,
            message: "Mot de passe requis",
          },
          ...registerOptions,
        }}
        {...rest}
      />
      <MbjIconButton
        size={"xs"}
        themeColor={"grey"}
        onClick={togglePassword}
        icon={passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
        className={"showButton"}
        ariaLabel={passwordShown ? "Cacher le mot de passe" : "Afficher le mot de passe"}
      />
    </div>
  );
}

/**
 * Représente un input :
 */
export const MbjPasswordSimpleInput = styled(MbjPasswordSimpleInputCtrl)((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "relative",

  input: {
    flexGrow: 1,
  },

  ".showButton": {
    position: "absolute",
    right: "5px",
  },
}));

MbjPasswordSimpleInput.defaultProps = {
  themeColor: "primary",
  noPadding: false,
};
