import * as TabsPrimitive from "@radix-ui/react-tabs";
import styled from "styled-components";

interface MbjTabsProps {}

/**
 * Doc : https://www.radix-ui.com/docs/primitives/components/tabs
 */
export const MbjTabs = styled(TabsPrimitive.Root)<MbjTabsProps>((props) => ({
  display: "flex",
  flexDirection: props.orientation === "horizontal" ? "column" : "row",
  flexGrow: 1,
  boxShadow: "2px 1px 4px 1px #bbbbbb21",
}));
MbjTabs.defaultProps = {
  orientation: "horizontal",
};
