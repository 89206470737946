import * as React from "react";
import styled from "styled-components";

export interface MbjLinkBoxCtrlProps extends React.HTMLProps<HTMLDivElement> {}

function MbjLinkBoxCtrl({ children, ...rest }: MbjLinkBoxCtrlProps) {
  return <div {...rest}>{children}</div>;
}

export const MbjLinkBox = styled(MbjLinkBoxCtrl)<MbjLinkBoxCtrlProps>((props) => ({
  position: "relative",

  "a[href]:not(.MbjLinkOverlay)": {
    position: "relative",
    zIndex: 1,
  },
}));
MbjLinkBox.defaultProps = {};
