import * as React from "react";
import styled from "styled-components";

interface MbjWidgetHeaderCtrlProps {
  /**
   * Classe HTML de l'élément?
   */
  className?: string;
  /**
   * Header de la card ?
   */
  children?: React.ReactNode;
}

function MbjWidgetHeaderCtrl(props: MbjWidgetHeaderCtrlProps) {
  return <div className={`MbjWidgetHeader ${props.className}`}>{props.children}</div>;
}

export const MbjWidgetHeader = styled(MbjWidgetHeaderCtrl)((props) => ({}));
MbjWidgetHeader.defaultProps = {};
