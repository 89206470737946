import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

export function AnchorScroller(props: Props) {
  const location = useLocation();

  useEffect(() => {
    if (location.hash !== "") {
      const elementId = location.hash.substring(1);

      const element = document.getElementById(elementId);
      if (element === null) {
        return;
      }

      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.hash]);

  return props.children as JSX.Element;
}
