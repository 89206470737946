import React, { useMemo } from "react";
import styled from "styled-components";
import { useGetOffrePEPart } from "@mbj-front-monorepo/data-access";
import { MbjLoading, MbjNoResult, MbjScrollArea } from "@mbj-front-monorepo/ui";
import { MbjOffrePeItem } from "@mbj-front-monorepo/data-ui";

interface OffresPECtrlProps {
  className?: string;

  particulierId: number;
}

const OffresPECtrl = (props: OffresPECtrlProps) => {
  const OffresPEQuery = useGetOffrePEPart(props.particulierId);

  const ListOffresFiltered = useMemo(() => {
    if (OffresPEQuery.isLoading || OffresPEQuery.isError || !OffresPEQuery.data) {
      return [];
    } else {
      return OffresPEQuery.data;
    }
  }, [OffresPEQuery.data, OffresPEQuery.isLoading, OffresPEQuery.isError]);

  return (
    <div className={props.className}>
      {OffresPEQuery.isLoading ? (
        <MbjLoading />
      ) : OffresPEQuery.isError ? (
        <p>Erreur ...</p>
      ) : ListOffresFiltered.length === 0 ? (
        <MbjNoResult text={"Aucune offre"} />
      ) : (
        <MbjScrollArea>
          {ListOffresFiltered.map((offrePoleEmploi, idx) => {
            return (
              //Les ids des Offres pole emploi ne sont pas uniques donc on utilise l'index
              <MbjOffrePeItem OffrePE={offrePoleEmploi} key={idx} />
            );
          })}
        </MbjScrollArea>
      )}
    </div>
  );
};

export const OffresPE = styled(OffresPECtrl)({
  height: "100%",
});
