import React from "react";
import styled from "styled-components";
import { Operation, useMutateFiPart } from "@mbj-front-monorepo/data-access";
import {
  MbjButton,
  MbjHeading,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import { useForm } from "react-hook-form";
import { getDateEN, GetElementType } from "@mbj-front-monorepo/utils";

interface MbjFormationInitialePartEditFormCtrlProps {
  className?: string;
  ParticulierFi: GetElementType<Operation<"getParticulierFiCollection">>;
  hideModal?: () => void;
}

const MbjFormationInitialePartEditFormCtrl = (props: MbjFormationInitialePartEditFormCtrlProps) => {
  const mutation = useMutateFiPart(props.ParticulierFi.id || -1);

  const defaultValues = {
    nom: props.ParticulierFi.nom,
    ObtentionAt: props.ParticulierFi.ObtentionAt ? getDateEN(new Date(props.ParticulierFi.ObtentionAt)) : "",
    description: props.ParticulierFi.description,
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Operation<"putParticulierFiItem", "requestBody">>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  return (
    <form
      className={props.className}
      onSubmit={handleSubmit((data) => {
        mutation.mutate(data, {
          onSettled: () => {
            if (props.hideModal !== undefined) {
              props.hideModal();
            }
          },
        });
      })}
    >
      <MbjHeading as={"h2"}>Formation initiale en cours de modification : {props.ParticulierFi.nom}</MbjHeading>
      <MbjSimpleInputContainer errors={errors} label={"Nom exact du diplôme *"}>
        <MbjSimpleInput
          name={"nom"}
          id={"nom"}
          type={"text"}
          noPadding
          register={register}
          registerOptions={{
            required: {
              value: true,
              message: "Nom requis",
            },
            minLength: {
              value: 5,
              message: "Nom trop court",
            },
          }}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Date d'obtention *"}>
        <MbjSimpleInput
          name={"ObtentionAt"}
          id={"ObtentionAt"}
          type={"date"}
          noPadding
          register={register}
          registerOptions={{
            required: {
              value: true,
              message: "Date d'obtention requise",
            },
          }}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Description"}>
        <MbjSimpleTextArea name={`description`} id={`description`} noPadding register={register} />
      </MbjSimpleInputContainer>
      <MbjButton isPending={mutation.isLoading}>Confirmer</MbjButton>
    </form>
  );
};

export const MbjParticulierFiEditForm = styled(MbjFormationInitialePartEditFormCtrl)((props) => ({
  gap: "8px",
  display: "flex",
  flexDirection: "column",

  button: {
    alignSelf: "flex-end",
  },
}));
