import * as React from "react";
import { ForgotPasswordForm } from "./ForgotPasswordForm/ForgotPasswordForm";
import styled, { useTheme } from "styled-components";
import { logoMBJ, MbjAvatar, MbjBack } from "@mbj-front-monorepo/ui";

interface ForgotPasswordCtrlProps {
  className?: string;
}

function ForgotPasswordCtrl(props: ForgotPasswordCtrlProps) {
  const theme = useTheme();
  return (
    <div className={"ForgotPassword " + props.className}>
      <div className="imageBack">
        <img src={theme.logo} alt="logo mbj" />
      </div>
      <div className="forgot-password-container">
        <MbjBack height="29px" className="MbjBack" />
        <MbjAvatar className="MbjBadge" src={logoMBJ} objectFit={"contain"} bg={"#ffffff"} withShadow padding={"6px"} />
        <h1>Mot de passe oublié</h1>
        <p>
          Demande de réinitialisation de votre mot de passe <br />
          Afin de réinitialiser votre mot de passe, renseigner votre adresse email
        </p>
        <ForgotPasswordForm />
      </div>
    </div>
  );
}
export const ForgotPassword = styled(ForgotPasswordCtrl)((props) => ({
  backgroundColor: props.theme.background1,
  minHeight: "100vh",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 10px",

  "& .imageBack": {
    position: "fixed",
    width: "60vw",
    height: "100%",
    backgroundColor: props.theme.dark,
    top: 0,
    left: 0,
    boxShadow: "6px 0px 11px #00000040",
    img: {
      position: "absolute",
      left: "10px",
      top: "10px",
      maxWidth: "257px",
      width: "80%",
      zIndex: 1,
    },
  },

  "& .forgot-password-container": {
    position: "relative",
    display: "flex",
    borderTop: "30px solid " + props.theme.primaryPastel,
    flexDirection: "column",
    textAlign: "center",
    width: "30%",
    padding: "30px",
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    minHeight: "385px",
    minWidth: "fit-content",
    zIndex: 2,
    boxShadow: "0px 3px 6px #00000029",

    ".MbjBadge": {
      position: "absolute",
      top: "-20px",
      right: "20px",
    },
    ".MbjBack": {
      position: "absolute",
      top: "12px",
      left: "21px",
    },

    p: {
      paddingTop: "10px",
    },

    form: {
      paddingTop: "34px",
    },
  },
}));
