import {
  MbjButton,
  MbjEmailSimpleInput,
  MbjFlex,
  MbjPasswordSimpleInput,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Operation, useRegisterParticulierOrly } from "@mbj-front-monorepo/data-access";

interface RegistrationFormCtrlProps {
  className?: string;

  location: any;
}

function RegistrationFormCtrl(props: RegistrationFormCtrlProps) {
  const mutationAddParticulier = useRegisterParticulierOrly();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<Operation<"addOrlyUserCollection", "requestBody">>({
    mode: "onChange",
  });

  const password = watch("password", "");

  return (
    <form
      onSubmit={handleSubmit((data) => {
        console.log(data);
        mutationAddParticulier.mutate(data);
      })}
      className={"ConnexionFormLogin" + props.className}
    >
      <MbjFlex>
        <MbjSimpleInputContainer errors={errors} label={"Nom *"}>
          <MbjSimpleInput
            name={"nom"}
            id={"nom"}
            register={register}
            registerOptions={{
              required: {
                value: true,
                message: "Nom  requis",
              },
            }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Prénom *"}>
          <MbjSimpleInput
            name={"prenom"}
            id={"prenom"}
            register={register}
            registerOptions={{
              required: {
                value: true,
                message: "Prénom requis",
              },
            }}
          />
        </MbjSimpleInputContainer>
      </MbjFlex>
      <MbjFlex>
        <MbjSimpleInputContainer errors={errors} label={"Nom d'utilisateur *"}>
          <MbjSimpleInput
            name={"username"}
            id={"username"}
            register={register}
            registerOptions={{
              required: {
                value: true,
                message: "Nom d'utilisateur requis",
              },
            }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Adresse mail *"}>
          <MbjEmailSimpleInput
            name={"mail"}
            id={"mail"}
            register={register}
            registerOptions={{
              required: {
                value: true,
                message: "Adresse mail requise",
              },
            }}
          />
        </MbjSimpleInputContainer>
      </MbjFlex>
      <MbjFlex>
        <MbjSimpleInputContainer errors={errors} label={"Code postal *"}>
          <MbjSimpleInput
            name={"codepostal"}
            id={"codepostal"}
            type={"text"}
            noPadding
            register={register}
            registerOptions={{
              required: {
                value: true,
                message: "Code postal requis",
              },
            }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={errors} label={"Ville *"}>
          <MbjSimpleInput
            name={"ville"}
            id={"ville"}
            type={"text"}
            noPadding
            register={register}
            registerOptions={{
              required: {
                value: true,
                message: "Ville requise",
              },
            }}
          />
        </MbjSimpleInputContainer>
      </MbjFlex>
      <MbjSimpleInputContainer errors={errors} label={"Mot de passe *"}>
        <MbjPasswordSimpleInput name={"password"} id={"password"} register={register} />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Confirmation du mot de passe *"}>
        <MbjPasswordSimpleInput
          name={"confirmPassword"}
          id={"confirmPassword"}
          register={register}
          registerOptions={{
            validate: (value) => value === password || "Les mots de passes ne correspondent pas",
          }}
        />
      </MbjSimpleInputContainer>

      <MbjButton themeColor="primary" isPending={mutationAddParticulier.isLoading}>
        Inscription
      </MbjButton>
      {/*<MbjLink to="/forgot-password">Mot de passe oublié ?</MbjLink>*/}
    </form>
  );
}

export const RegistrationForm = styled(RegistrationFormCtrl)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",

  ".rowContainer": {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },

  "& .incorrect": {
    color: "#bf1650",
    fontSize: "16px",
  },
}));
