import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import logoLoading from '../../../assets/icons/logoMbjAnim.svg';

interface MbjLoadingCtrlProps {
  /**
   * Couleur utilisée pour le style de chargement :
   */
  themeColor?: 'primary' | 'secondary' | 'third' | 'complementary' | 'white';
  /**
   * Classe HTML :
   */
  className?: string;
  /**
   * Hauteur du loader :
   */
  height?: string;
  /**
   * Spinner ?
   */
  isSpinner?: boolean;
  /**
   * margin ?
   */
  noMargin?: boolean;
}
function MbjLoadingCtrl(props: MbjLoadingCtrlProps) {
  return (
    <div className={props.className}>
      {!props.isSpinner ? (
        <object type="image/svg+xml" data={logoLoading}>
          svg-animation
        </object>
      ) : (
        <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle className="load one" cx="60" cy="60" r="40" />
          <circle className="load two" cx="60" cy="60" r="40" />
          <circle className="load three" cx="60" cy="60" r="40" />
          <g>
            <circle className="point one" cx="45" cy="70" r="5" />
            <circle className="point two" cx="60" cy="70" r="5" />
            <circle className="point three" cx="75" cy="70" r="5" />
          </g>
        </svg>
      )}
    </div>
  );
}
const bounce = keyframes`
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
`;
const load = keyframes`
    0% {
        stroke-dashoffset: 570;
    }
    50% {
        stroke-dashoffset: 530;
    }
    100% {
        stroke-dashoffset: 570;
        transform: rotate(360deg);
    }
`;
/**
 * Composant contenant une animation de "chargement"
 */
export const MbjLoading = styled(MbjLoadingCtrl)(
  (props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: props.height,
    aspectRatio: props.isSpinner ? '1 / 1' : '',
    margin: props.isSpinner || props.noMargin ? '0' : '15px 0',
    object: {
      height: '100%',
    },

    svg: {
      fill: 'none',
      '& .load': {
        transformOrigin: '50% 50%',
        strokeDasharray: 570,
        strokeWidth: '20px',
        '&.one': {
          stroke: props.theme.primary,
          //animation: `${load} 1.5s infinite`,
        },
        '&.two': {
          stroke: props.theme.secondary,
          //animation: `${load} 1.5s infinite`,
          animationDelay: '0.1s',
        },
        '&.three': {
          stroke: props.theme.primary,
          //animation: `${load} 1.5s infinite`,
          animationDelay: '0.2s',
        },
      },

      '& .point': {
        //animation: `${bounce} 1s infinite ease-in-out`,
        '&.one': {
          fill: props.theme.primary,
          animationDelay: '0s',
        },
        '&.two': {
          fill: props.theme.secondary,
          animationDelay: '0.1s',
        },
        '&.three': {
          fill: props.theme.third,
          animationDelay: '0.2s',
        },
      },
    },
  }),
  css`
    .load.one,
    .load.two,
    .load.three {
      animation: ${load} 1.5s infinite;
    }
    .point {
      animation: ${bounce} 1.5s infinite;
    }
  `
);
MbjLoading.defaultProps = {
  themeColor: 'primary',
  height: '50px',
  isSpinner: false,
  noMargin: false,
};
