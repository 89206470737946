import React from "react";
import styled from "styled-components";
import { FlexItemOptions, useFlexItem } from "../../flex-utils";

interface MbjScrollAreaCtrlProps extends FlexItemOptions {
  /**
   * Classe HTML :
   */
  className?: string;

  /**
   * Contenu
   */
  children?: React.ReactNode;
  /**
   * hauteur
   */
  maxHeight?: string;

  overflowX?: React.CSSProperties["overflowX"];

  innerRef?: React.Ref<HTMLDivElement>;
}

function MbjScrollAreaCtrl(props: MbjScrollAreaCtrlProps) {
  //Lorsque la hauteur maximale n'est pas définie, je wrappe le contenu dans une div pour que le scroll fonctionne sur toute la hauteur dynamiquement.
  if (!props.maxHeight) {
    return (
      <div className={"dynamicHeight " + props.className}>
        <div className={props.className} ref={props.innerRef}>
          {props.children}
        </div>
      </div>
    );
  }
  return (
    <div className={props.className} ref={props.innerRef}>
      {props.children}
    </div>
  );
}

/**
 * Composant représentant une zone de scroll
 */
export const MbjScrollArea = styled(MbjScrollAreaCtrl)((props) => ({
  //Lorsque la hauteur maximale est définie, on ajoute juste un scroll
  "&:not(.dynamicHeight)": {
    maxHeight: props.maxHeight ? props.maxHeight : "none",
    overflowY: "auto",
    overflowX: props.overflowX ? props.overflowX : "hidden",
  },
  width:"100%",

  //Lorsque la hauteur maximale n'est pas définie, on doit ruser pour que le scroll fonctionne sur toute la hauteur dynamiquement.
  "&.dynamicHeight": {
    height: !props.grow ? "100%" : "auto",
    position: "relative",
    ...useFlexItem(props),

    "& > *": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
}));
MbjScrollArea.defaultProps = {};
