import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BaseColorsTheme,
  MbjAccordion,
  MbjAccordionItem,
  MbjButton,
  MbjFlex,
  MbjLoading,
  MbjNoResult,
  MbjScrollArea,
  MbjSearchBar,
} from "@mbj-front-monorepo/ui";
import { useGetParticulierCompetences } from "@mbj-front-monorepo/data-access";
import styled from "styled-components";
import { MbjParticulierLineCompetence } from "./parts/MbjParticulierLineCompetence";

interface MbjParticulierActiviteAccordionCtrlProps {
  className?: string;

  themeColor?: BaseColorsTheme;

  particulierId?: number;

  isEditable?: boolean;

  callToAction?: boolean;
}

const MbjParticulierActiviteAccordionCtrl = (props: MbjParticulierActiviteAccordionCtrlProps) => {
  const navigate = useNavigate();
  const queryCompetencesParticulier = useGetParticulierCompetences(props.particulierId);
  const [search, setSearch] = useState<string>();

  const CompFiltered = useMemo(() => {
    if (!queryCompetencesParticulier || !queryCompetencesParticulier.data) return [];
    if (!search || search === "") return queryCompetencesParticulier.data;
    return queryCompetencesParticulier.data.filter((comp) => {
      if (comp.Competence?.libelle?.toLowerCase().includes(search.toLowerCase())) return true;
      if (comp.ParticulierActivites) {
        for (const employeeActivite of comp.ParticulierActivites) {
          if (employeeActivite.Activite?.libelle?.toLowerCase().includes(search.toLowerCase())) return true;
        }
      }
      return false;
    });
  }, [queryCompetencesParticulier.data, search]);

  return (
    <MbjFlex direction={"column"} className={props.className} wrap={"nowrap"} grow={1}>
      {queryCompetencesParticulier.isLoading ? (
        <MbjLoading />
      ) : queryCompetencesParticulier.isError ? (
        <p>Erreur ...</p>
      ) : queryCompetencesParticulier.data.length === 0 ? (
        <div className={"noCompetences"}>
          <MbjNoResult text={"Aucune compétences pour le moment"} />
          {props.callToAction && (
            <>
              <div>Vous pouvez en ajouter manuellement sur cette page ou étoffer son CV :</div>
              <MbjButton size={"xs"} onClick={() => navigate("./../cv")}>
                Étoffer son CV
              </MbjButton>
            </>
          )}
        </div>
      ) : (
        <>
          <MbjSearchBar
            id={"sarchComp"}
            placeholder={"Rechercher une compétence"}
            className={"SearchBar"}
            onChange={(event) => setSearch(event.target.value)}
          />
          <MbjScrollArea>
            <MbjAccordion type="multiple" defaultValue={[]} className={"accordéon"}>
              {CompFiltered.map((CE, idx) => (
                <MbjAccordionItem value={`item-${idx}`} key={idx}>
                  <MbjParticulierLineCompetence
                    key={idx}
                    CompetencesParticulier={CE}
                    isEditable={props.isEditable}
                    idParticulier={props.particulierId || -1}
                    isLoading={queryCompetencesParticulier.isLoading}
                    isError={queryCompetencesParticulier.isError}
                    ParticulierActivites={CE.ParticulierActivites}
                    themeColor={props.themeColor}
                  />
                </MbjAccordionItem>
              ))}
            </MbjAccordion>
          </MbjScrollArea>
        </>
      )}
    </MbjFlex>
  );
};
export const MbjParticulierActiviteAccordion = styled(MbjParticulierActiviteAccordionCtrl)((props) => ({
  ".noCompetences": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    padding: "10px",
  },
}));
MbjParticulierActiviteAccordion.defaultProps = {
  themeColor: "grey",
  callToAction: true,
  isEditable: true,
};
