import React from 'react';
import styled from 'styled-components';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { SwitchProps } from '@radix-ui/react-switch';

interface MbjSwitchProps {}

/**
 * A control that allows the user to toggle between checked and not checked.
 * https://www.radix-ui.com/docs/primitives/components/switch#root
 */
export const MbjSwitch = styled(SwitchPrimitive.Root)<MbjSwitchProps & SwitchProps>((props) => ({
  all: 'unset',
  width: 40,
  height: 20,
  backgroundColor: props.theme.background2,
  borderRadius: '9999px',
  position: 'relative',
  border: '2px solid ' + props.theme.primaryPastel,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  '&:focus': { boxShadow: `0 0 0 1px inset black` },
  '&[data-state="checked"], &[aria-checked="true"]': { borderColor: props.theme.primaryDarken },
}));

MbjSwitch.defaultProps = {};
