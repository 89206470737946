import * as React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { MbjMenuLinks } from "../../../molecules/MbjMenu/MbjMenuLinks";
import { MbjHeadBarMobile } from "../../../molecules/MbjHeadBar/mobile/MbjHeadBarMobile";
import { MbjMenuMobile } from "../../../molecules/MbjMenu/mobile/MbjMenuMobile";

interface MobileLayoutProps {
  /**
   * Classe HTML de desktop layout :
   */
  className?: string;
  /**
   * Représente le site vers lequel l'utilisateur peut aller (POUR DEBUG)
   */
  type?: string;
  /**
   *
   */
  menuLinks: MbjMenuLinks[];
  /**
   *
   */
  disconnectionCallback?: () => void;
}

function MainMobileLayoutCtrl(props: MobileLayoutProps) {
  return (
    <div className={"MobileLayout " + props.className}>
      <MbjHeadBarMobile className="MbjHeadBar" disonnectionCallback={props.disconnectionCallback} />
      <div className="main">
        <div className="section">
          {/*props.type*/}
          <Outlet />
        </div>
        <MbjMenuMobile className="MbjMenu" menuLinks={props.menuLinks} />
      </div>
    </div>
  );
}
export const MainMobileLayout = styled(MainMobileLayoutCtrl)((props) => ({
  width: "100vw",
  height: "100%",

  display: "flex",
  flexDirection: "column",
  "& .main": {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,

    "& .MbjMenu": {
      zIndex: 1,
      flexShrink: 0,
    },

    "& .section": {
      display: "flex",
      flexGrow: 1,
      overflowY: "hidden",
      position: "relative",
      backgroundColor: props.theme.background1,

      "& > *": {
        maxHeight: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      },
    },
  },
}));
