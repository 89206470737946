import React from "react";

import styled from "styled-components/macro";
import { NavLink, To } from "react-router-dom";
import { BaseColorsTheme } from "../../../../assets/styles/ThemeType";

interface MbjItemMenuDesktopCtrlProps {
  children?: any;
  /**
   * label de l'item menu
   */
  label?: string;
  /**
   * Classe HTML de l'ItemMenu
   */
  className?: string;

  /**
   * Couleur utilisée pour représenter l'ordre d'importance dee l'item Menu sur la page :
   */
  themeColor?: BaseColorsTheme;
  /**
   * Cet Item est-il actif (suis-je sur la page qu'il est censé représenter ?)
   */
  isActive?: boolean;
  /**
   * L'item menu doit-il être replié ?
   */
  isFold?: boolean;
  /**
   * L'item Menu doit-il renvoyé sur une autre page ?
   *
   * A string representation of the Link location, created by concatenating the location’s pathname, search, and hash properties.
   * https://v5.reactrouter.com/web/api/Link/to-string
   */
  to: To;
  /**
   *
   */
  location?: string;
}

function MbjItemMenuDesktopCtrl(props: MbjItemMenuDesktopCtrlProps) {
  return (
    <NavLink to={props.to} className={props.className}>
      {React.cloneElement(props.children, { size: "20px" })}
      {<div className={"label " + props.isFold}>{props.label}</div>}
    </NavLink>
  );
}

/**
 * Composant représentant un "item" du menu de navigation principal sur ordinateur
 */
export const MbjItemMenuDesktop = styled(MbjItemMenuDesktopCtrl)((props) => ({
  display: "flex",
  alignItems: "center",
  lineHeight: "1em",
  textDecoration: "none",
  fontSize: "calc(8px + 0.9vmin)",
  color: !props.isActive ? props.theme.background2 : props.themeColor ? props.theme[props.themeColor] : "none",
  backgroundColor: !props.isActive ? props.theme.dark : props.theme.background2,
  cursor: "pointer",
  borderLeft: !props.isActive ? "0 solid transparent" : "10px solid",
  paddingTop: "8px",
  paddingBottom: "8px",
  marginBottom: "8px",
  paddingLeft: "15px",
  paddingRight: props.isFold ? "15px" : "24.6px",
  borderColor: props.themeColor ? props.theme[props.themeColor] : "none",
  transitionDuration: "0.25s",
  transitionProperty: "border-left, padding-left, padding-right",
  transitionTimingFunction: "linear",

  "& .label": {
    opacity: props.isFold ? "0" : "1",
    visibility: props.isFold ? "hidden" : "visible",
    width: props.isFold ? "0px" : "fit-content",
    height: props.isFold ? "0px" : "fit-content",
    marginLeft: props.isFold ? "0px" : "15px",
    transitionDuration: "0.3s",
    transitionProperty: "opacity, visibility, width, height, marginLeft, padding-right",
    transitionTimingFunction: "linear",
  },

  "&:hover, &.active": {
    color: props.themeColor ? props.theme[props.themeColor] : "none",
    borderLeft: "10px solid",
    paddingLeft: props.isFold ? "10px" : "30px",
    paddingRight: "0px",
    backgroundColor: props.theme.background2,
  },
}));
MbjItemMenuDesktop.defaultProps = {
  themeColor: "secondary",
  isFold: false,
  isActive: false,
};
