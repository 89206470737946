import * as React from "react";
import styled from "styled-components";
import { Link, To } from "react-router-dom";

export interface MbjLinkOverlayCtrlProps {
  className?: string;
  to: To;
  /**
   * Texte
   */
  text?: string;
  children?: React.ReactNode;
}

function MbjLinkOverlayCtrl(props: MbjLinkOverlayCtrlProps) {
  return (
    <Link className={`MbjLinkOverlay ${props.className}`} to={props.to}>
      {props.children || props.text}
    </Link>
  );
}

export const MbjLinkOverlay = styled(MbjLinkOverlayCtrl)<MbjLinkOverlayCtrlProps>((props) => ({
  all: "unset",
  position: "static",
  "&:focus-visible": {
    outline: "2px solid blue",
  },

  "&::before": {
    content: "''",
    cursor: "inherit",
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    width: "100%",
    height: "100%",
  },
}));
MbjLinkOverlay.defaultProps = {};
