import React from "react";
import styled from "styled-components";
import noResult from "../../../assets/images/NoResult.png";

interface MbjNoResultCtrlProps {
  text?: string;
  className?: string;
  padding?: string;
  margin?: string;
  width?: string;
}

const MbjNoResultCtrl = ({ className, text }: MbjNoResultCtrlProps) => {
  return (
    <div className={`noRes ${className}`}>
      <div className="image-place">
        <img src={noResult} alt={"aucun résultat"} />
      </div>
      <div className="text-place">{text}</div>
    </div>
  );
};
export const MbjNoResult = styled(MbjNoResultCtrl)((props) => ({
  width: props.width ? props.width : "150px",
  margin: props.margin ? props.margin : "auto",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  ".image-place": {
    flexGrow: 1,
    padding: "0.5rem",
    textAlign: "center",
    img: {
      width: "50%",
      height: "auto",
    },
  },
  ".text-place": {
    padding: "0.25rem",
    fontSize: "14px",
    textAlign: "center",
    color: props.theme.greyLighten,
  },
}));
