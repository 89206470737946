import React, { useMemo } from "react";
import styled from "styled-components";
import { useGetOffreInternePart } from "@mbj-front-monorepo/data-access";
import { MbjLoading, MbjNoResult, MbjScrollArea } from "@mbj-front-monorepo/ui";
import { MbjOffreInterneItem } from "@mbj-front-monorepo/data-ui";

interface OffresInternesCtrlProps {
  className?: string;

  particulierId: number;
}

const OffresInternesCtrl = (props: OffresInternesCtrlProps) => {
  const offreInternesQuery = useGetOffreInternePart(props.particulierId);

  const ListOffresFiltered = useMemo(() => {
    if (offreInternesQuery.isError || offreInternesQuery.isLoading || !offreInternesQuery.data) {
      return [];
    } else {
      return offreInternesQuery.data;
    }
  }, [offreInternesQuery.isError, offreInternesQuery.isLoading, offreInternesQuery.data]);

  return (
    <div className={props.className}>
      {offreInternesQuery.isLoading ? (
        <MbjLoading />
      ) : offreInternesQuery.isError ? (
        <p>Erreur ...</p>
      ) : ListOffresFiltered.length === 0 ? (
        <MbjNoResult text={"Aucune offre"} />
      ) : (
        <MbjScrollArea>
          {ListOffresFiltered.map((item, idx) => (
            <MbjOffreInterneItem key={item.id} OffreInterne={item} />
          ))}
        </MbjScrollArea>
      )}
    </div>
  );
};

export const OffresInternes = styled(OffresInternesCtrl)((props) => ({
  height: "100%",
}));
