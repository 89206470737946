import React from "react";
import styled from "styled-components";

interface MbjDividerCtrlProps extends React.HTMLAttributes<HTMLHRElement> {
  orientation?: "horizontal" | "vertical";

  border?: "solid" | "dashed" | "dotted" | "double" | "groove" | "ridge";

  isFlexChild?: boolean;
}
function MbjDividerCtrl({ orientation, style, isFlexChild, ...rest }: MbjDividerCtrlProps) {
  return <hr {...rest} aria-orientation={orientation}></hr>;
}

const dividerStyles = (isFlexChild?: boolean) => {
  return {
    vertical: {
      borderWidth: "0 0 0 2px",
      height: !isFlexChild ? "100%" : "auto",
      alignSelf: isFlexChild ? "stretch" : "auto",
      width: "0px",
    },
    horizontal: {
      borderWidth: "0 0 2px 0",
      width: !isFlexChild ? "100%" : "auto",
      flexGrow: isFlexChild ? "1" : "auto",
      height: "0px",
    },
  };
};

export const MbjDivider = styled(MbjDividerCtrl)(({ orientation, border, isFlexChild, ...props }) => ({
  //all: "unset",
  borderStyle: border || "solid",
  borderRadius: "50px",
  ...dividerStyles(isFlexChild)[orientation || "horizontal"],
}));
MbjDivider.defaultProps = {
  orientation: "horizontal",
};
