import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { AccordionMultipleProps, AccordionSingleProps } from '@radix-ui/react-accordion';
import styled from 'styled-components';

interface MbjAccordionProps {}

/**
 * Doc : https://www.radix-ui.com/docs/primitives/components/accordion
 */
export const MbjAccordion = styled(AccordionPrimitive.Root)<
  MbjAccordionProps & (AccordionSingleProps | AccordionMultipleProps)
>((props) => ({
  //borderRadius: "0.4em",
  flexGrow: 1,
  backgroundColor: props.theme.background2,
  boxShadow: '2px 1px 4px 1px #bbbbbb21',
  h3: {
    margin: 0,
  },
}));
MbjAccordion.defaultProps = {};
