import * as React from "react";
import styled from "styled-components";
import { MbjButton } from "../../atoms/MbjButton/MbjButton";
import { MbjModal, MbjModalCtrlProps } from "./MbjModal";

export interface MbjConfirmModalCtrlProps extends MbjModalCtrlProps {
  /**
   * Texte de confirmation à afficher
   */
  confirmText: string;
  /**
   * Le bouton de confirmation être il en train de charger ?
   */
  isLoading?: boolean;
  /**
   * Fonction de callback pour la confirmation
   */
  confirmFunction?: any;
}

function MbjConfirmModalCtrl(props: MbjConfirmModalCtrlProps) {
  return (
    <MbjModal {...props}>
      <p>{props.confirmText}</p>
      <div className={"interactContainer"}>
        <MbjButton themeColor="primary" size={"sm"} onClick={props.hide}>
          Je vais réfléchir
        </MbjButton>
        <MbjButton themeColor="warning" size={"sm"} isPending={props.isLoading} onClick={props.confirmFunction}>
          Oui, je suis sûr
        </MbjButton>
      </div>
    </MbjModal>
  );
}

export const MbjConfirmModal = styled(MbjConfirmModalCtrl)((props) => ({
  ".modalBody": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  p: {
    fontSize: "16px",
    textAlign: "center",
  },
  ".interactContainer": {
    margin: "15px auto",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
}));
MbjConfirmModal.defaultProps = {};
