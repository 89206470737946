import React from "react";
import styled from "styled-components";
import { Operation, useDeleteXpPart } from "@mbj-front-monorepo/data-access";
import { BiEdit } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import {
  MbjCard,
  MbjCardBody,
  MbjCardCtrlProps,
  MbjCardFooter,
  MbjCardHeader,
  MbjConfirmModal,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjModal,
  MbjStaticInfo,
  useModal,
} from "@mbj-front-monorepo/ui";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { MbjParticulierXpEditForm } from "./MbjParticulierXpEditForm";

interface MbjParticulierXpCardCtrlProps extends MbjCardCtrlProps {
  ParticulierXp: GetElementType<Operation<"getParticulierXpCollection">>;
}

const MbjParticulierXpCardCtrl = (props: MbjParticulierXpCardCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const mutateDelete = useDeleteXpPart();
  const HandleConfirmDelete = () => {
    mutateDelete.mutate(props.ParticulierXp.id || -1, {
      onSettled: () => {
        toggleConfirm();
      },
    });
  };
  return (
    <>
      <MbjCard {...props}>
        <MbjCardHeader>
          <MbjHeading>
            Du {props.ParticulierXp.startAt ? getDateFR(new Date(props.ParticulierXp.startAt)) : "inconnu"} à{" "}
            {props.ParticulierXp.endAt ? getDateFR(new Date(props.ParticulierXp.endAt)) : "maintenant"} :{" "}
            {props.ParticulierXp.Metier?.libelle}
          </MbjHeading>
        </MbjCardHeader>
        <MbjCardBody>
          <MbjFlex direction={"column"}>
            <MbjFlex gap={"15px"}>
              <MbjStaticInfo
                label="Type de contrat : "
                info={props.ParticulierXp.TypeContrat ? props.ParticulierXp.TypeContrat.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
              <MbjStaticInfo
                label="Salaire : "
                info={props.ParticulierXp.salary ? props.ParticulierXp.salary + "€" : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
            </MbjFlex>
            {props.ParticulierXp.description && (
              <MbjStaticInfo label="Description : " info={props.ParticulierXp.description} gap={"5px"} />
            )}
          </MbjFlex>
        </MbjCardBody>
        <MbjCardFooter>
          <MbjFlex direction={"row"}>
            <MbjIconButton
              onClick={toggle}
              ariaLabel={"Editer l'expérience"}
              icon={<BiEdit />}
              themeColor={"secondary"}
              isRound
            />
            <MbjIconButton
              onClick={toggleConfirm}
              ariaLabel={"Supprimer l'expérience"}
              icon={<BsTrash />}
              themeColor={"warning"}
              isRound
            />
          </MbjFlex>
        </MbjCardFooter>
        <MbjModal isShowing={isShowing} hide={toggle} title="Modification d'une expérience professionnelle">
          <MbjParticulierXpEditForm ParticulierXp={props.ParticulierXp} hideModal={toggle} />
        </MbjModal>
      </MbjCard>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression expérience"
        confirmText={"Voulez-vous vraiment supprimer cette expérience ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutateDelete.isLoading}
      />
    </>
  );
};

export const MbjParticulierXpCard = styled(MbjParticulierXpCardCtrl)((props) => ({}));
