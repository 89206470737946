import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";

export const useGetNiveaux = () => {
  const promise = () => fetchApi("/api/niveau_etudes", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["niveaux_etudes"], promise, {});
};

export const useGetDisciplines = () => {
  const promise = () => fetchApi("/api/disciplines", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["disciplines"], promise, {});
};
