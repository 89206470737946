import React from "react";
import styled from "styled-components";
import { MbjLabel } from "../MbjLabel/MbjLabel";

interface MbjListHeaderCtrlProps {
  /**
   * Classe HTML :
   */
  className?: string;
  /**
   * Couleur utilisée pour représenter l'ordre d'importance sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary" | "white";
  /**
   * Titre ou contenu
   */
  title?: string;
  children?: React.ReactNode;
  /**
   * padding
   */
  padding?: string | number;
}
function MbjListHeaderCtrl(props: MbjListHeaderCtrlProps) {
  return (
    <div className={props.className}>
      {props.title ? (
        <MbjLabel themeColor={!(props.themeColor === "white") ? "white" : "dark"}>{props.title}</MbjLabel>
      ) : (
        <React.Fragment />
      )}
      {Array.isArray(props.children)
        ? props.children.map((child, i) => {
            return React.cloneElement(child, {
              themeColor: !(props.themeColor === "white") ? "white" : "dark",
              key: i,
            });
          })
        : props.children}
    </div>
  );
}

/**
 * Composant entête de liste
 */
export const MbjListHeader = styled(MbjListHeaderCtrl)((props) => ({
  display: "flex",
  color: !(props.themeColor === "white") ? props.theme.background2 : props.theme.dark,
  padding: props.padding,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "calc(10px + 0.9vmin)",
  backgroundColor:
    props.themeColor === "primary"
      ? props.theme.primaryPastel
      : props.themeColor === "secondary"
      ? props.theme.secondaryPastel
      : props.themeColor === "third"
      ? props.theme.thirdPastel
      : props.themeColor === "complementary"
      ? props.theme.complementaryPastel
      : props.themeColor === "white"
      ? props.theme.background2
      : "none",

  span: {
    lineHeight: "1em",
  },
}));
MbjListHeader.defaultProps = {
  themeColor: "primary",
  padding: "0.4rem 0.6rem",
};
