import styled from "styled-components";
import { MbjButton, MbjLoading, MbjModal, MbjNoResult, MbjWidgetClassic, useModal } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { useGetFiPart, useGetParticulierMe, useGetXpPart } from "@mbj-front-monorepo/data-access";
import {
  MbjParticulierFiCard,
  MbjParticulierFiNewForm,
  MbjParticulierXpCard,
  MbjParticulierXpNewForm,
} from "@mbj-front-monorepo/data-ui";
import { GoPlus } from "react-icons/go";

interface CVCtrlProps {
  className?: string;
}

function CVCtrl(props: CVCtrlProps) {
  const { isShowing: isShowingAddXp, toggle: toggleAddXp } = useModal();
  const { isShowing: isShowingAddFi, toggle: toggleAddFi } = useModal();

  const particulierQueryQuery = useGetParticulierMe();
  const xpQuery = useGetXpPart(particulierQueryQuery.data?.id);
  const FisQuery = useGetFiPart(particulierQueryQuery.data?.id);

  return (
    <div className={props.className}>
      {xpQuery.isLoading ? (
        <MbjLoading />
      ) : xpQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <MbjWidgetClassic title={"Expériences pros"} justify={"center"} bodyDirection={"column"}>
          <MbjButton leftIcon={<GoPlus />} onClick={toggleAddXp} alignSelf={"flex-end"}>
            Ajouter une expérience
          </MbjButton>
          {xpQuery.data && xpQuery.data.length > 0 ? (
            xpQuery.data?.map((xp, index) => <MbjParticulierXpCard ParticulierXp={xp} key={xp.id} />)
          ) : (
            <MbjNoResult text={"Aucune expérience trouvée"} />
          )}
          <MbjModal
            isShowing={isShowingAddXp}
            hide={toggleAddXp}
            fitContent
            title="Nouvelle expérience professionnelle"
          >
            <MbjParticulierXpNewForm idParticulier={particulierQueryQuery.data?.id || -1} hideModal={toggleAddXp} />
          </MbjModal>
        </MbjWidgetClassic>
      )}
      {FisQuery.isLoading ? (
        <MbjLoading />
      ) : FisQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <MbjWidgetClassic title={"Formations initiales"} bodyDirection={"column"}>
          <MbjButton leftIcon={<GoPlus />} onClick={toggleAddFi} alignSelf={"flex-end"}>
            Ajouter une formation
          </MbjButton>
          {FisQuery.data && FisQuery.data.length > 0 ? (
            FisQuery.data?.map((fi, index) => <MbjParticulierFiCard ParticulierFi={fi} key={fi.id} />)
          ) : (
            <MbjNoResult text={"Aucune formations initiales trouvés"} />
          )}
          <MbjModal isShowing={isShowingAddFi} hide={toggleAddFi} fitContent title="Nouvelle formation initiale">
            <MbjParticulierFiNewForm idParticulier={particulierQueryQuery.data?.id || -1} hideModal={toggleAddFi} />
          </MbjModal>
        </MbjWidgetClassic>
      )}
    </div>
  );
}
export const CV = styled(CVCtrl)((props) => ({}));
