import React from "react";
import styled from "styled-components";
import { MbjRangeStatic } from "@mbj-front-monorepo/ui";
import { Operation } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";

interface MbjCompareActiviteItemCtrlProps {
  className?: string;
  bilanItem: GetElementType<Operation<"getProximiteReferentielItem">["Shared" | "Missed" | "ToRised"]>;
  color: string;
}

const MbjCompareActiviteItemCtrl = (props: MbjCompareActiviteItemCtrlProps) => {
  return (
    <div className={props.className}>
      <div className="libelle">{props.bilanItem.libelle}</div>
      <div className="comparaison">
        <MbjRangeStatic nbPoints={5} currentPoint={props.bilanItem.niveauActuel || 0} customColor={props.color} />
        <MbjRangeStatic nbPoints={5} currentPoint={props.bilanItem.niveauAttendu || 0} customColor={"#8c8c8c"} />
      </div>
    </div>
  );
};

export const MbjCompareActiviteItem = styled(MbjCompareActiviteItemCtrl)((props) => ({
  padding: "0.5rem",
  width: "100%",
  ".libelle": {
    fontSize: "calc(7px + 0.9vmin)",
    lineHeight: "1em",
    marginBottom: "4.5px",
    color: props.theme.primary,
  },
  ".comparaison": {
    "& > *:first-child": { marginBottom: "6px" },
  },
}));
