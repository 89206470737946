import styled from "styled-components";
import { MbjFlex, MbjNavPath, MbjSearchBar } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { useGetMetiers } from "@mbj-front-monorepo/data-access";
import { useLocation, useNavigate } from "react-router-dom";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { MobilitesByMbjCard } from "./MobilitesByMbjCard/MobilitesByMbjCard";

interface MobilitesCtrlProps {
  className?: string;
}

function MobilitesCtrl(props: MobilitesCtrlProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const metiersQuery = useGetMetiers();

  const SelectMetier = (metier: GetElementType<typeof metiersQuery.data>) => {
    navigate(metier.id?.toString() || "-1");
  };

  return (
    <div className={props.className}>
      <MbjNavPath routePath={location.pathname} />
      <MbjFlex direction={"column"} grow={1}>
        <MbjSearchBar
          placeholder="Rechercher un métier"
          onClickHandle={SelectMetier}
          suggestionsQuery={metiersQuery}
          foldAfterChoose={true}
          labelKey={"libelle"}
        />
        <MobilitesByMbjCard />
      </MbjFlex>
    </div>
  );
}
export const Mobilites = styled(MobilitesCtrl)((props) => ({
  padding: "1% 2%",
}));
