import * as React from "react";
import { omit } from "@mbj-front-monorepo/utils";

export interface FlexContainerOptions {
  /**
   * Shorthand for flex-direction style property
   */
  direction?: React.CSSProperties["flexDirection"];
  /**
   * Shorthand for flex-wrap style property
   */
  wrap?: React.CSSProperties["flexWrap"];
  /**
   * Shorthand for align-items style property
   */
  align?: React.CSSProperties["alignItems"];
  /**
   * Shorthand for justify-content style property
   */
  justify?: React.CSSProperties["justifyContent"];
  /**
   * Shorthand for gap style property
   */
  gap?: React.CSSProperties["gap"];
}

export function useFlexContainer(options: FlexContainerOptions) {
  const { direction, wrap, align, justify, gap } = options;

  return {
    flexDirection: direction,
    flexWrap: wrap,
    alignItems: align,
    justifyContent: justify,
    gap: gap,
  };
}

export interface FlexItemOptions {
  /**
   * Shorthand for flex-basis style property
   */
  basis?: React.CSSProperties["flexBasis"];
  /**
   * Shorthand for flex-grow style property
   */
  grow?: React.CSSProperties["flexGrow"];
  /**
   * Shorthand for flex-shrink style property
   */
  shrink?: React.CSSProperties["flexShrink"];
  /**
   * Shorthand for flex style property
   */
  flex?: React.CSSProperties["flex"];

  alignSelf?: React.CSSProperties["alignSelf"];
}

export function useFlexItem(options: FlexItemOptions) {
  const { basis, grow, shrink, flex } = options;

  return {
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
    flex,
    alignSelf: options.alignSelf,
  };
}
export const omitFlexProps = <T extends Record<string, any>>(props: T) => {
  return omit(props, ["direction", "wrap", "align", "justify", "gap", "basis", "grow", "shrink", "flex", "alignSelf"]);
};
