import React from "react";
import "./ParticulierApp.scss";
import { MainDesktopLayout, MainMobileLayout, MbjMenuLinks } from "@mbj-front-monorepo/ui";
import { MdAccountCircle, MdExplore } from "react-icons/md";
import { GiSkills } from "react-icons/gi";
import { Navigate, Route, Routes } from "react-router-dom";
import { LegalMentions } from "../../../pages/LegalMentions/LegalMentions";
import { NotFound } from "../../../pages/NotFound/NotFound";
import { useWindowSize } from "@mbj-front-monorepo/utils";
import { logout } from "@mbj-front-monorepo/data-access";
import { Informations } from "../../../pages/Informations/Informations";
import { CV_Competences } from "../../../pages/CV_competences/Cv_competences";
import { Mobilites } from "../../../pages/Mobilites/Mobilites";
import { FaUserCircle } from "react-icons/fa";
import { MobiliteDetails } from "../../../pages/Mobilites/MobiliteDetails/MobiliteDetails";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { Offres } from "../../../pages/Offres/Offres";
import { PropositionOffreDetail } from "../../../pages/PropositionOffreDetail/PropositionOffreDetail";
import { useQueryClient } from "@tanstack/react-query";

export function ParticulierApp() {
  const menuLinks: MbjMenuLinks[] = [
    {
      label: "Informations",
      to: "informations",
      icon: <MdAccountCircle title="Informations icône" />,
    },
    {
      label: "CV",
      to: "cv",
      icon: <GiSkills title="cv icône" />,
    },
    { label: "Mobilités", to: "mobilites", icon: <MdExplore title="Mobilité icône" /> },
    { label: "Offres", to: "offres", icon: <BsFillBriefcaseFill title="Offres icône" /> },
  ];

  const size = useWindowSize();

  const queryClient = useQueryClient();

  return (
    <div className="ParticulierApp">
      <Routes>
        <Route
          element={
            size && size.width && size.width > 600 ? (
              <MainDesktopLayout
                type="Particulier"
                menuLinks={menuLinks}
                disconnectionCallback={() => logout(queryClient)}
                userLogo={<FaUserCircle className={"icon-profile-menu"} />}
              />
            ) : (
              <MainMobileLayout
                type="Particulier"
                menuLinks={menuLinks}
                disconnectionCallback={() => logout(queryClient)}
              />
            )
          }
        >
          <Route path="" element={<Navigate to="informations" />} />
          <Route path="/informations" element={<Informations />} />
          <Route path="/cv/*" element={<CV_Competences />} />
          <Route path="/mobilites" element={<Mobilites />} />
          <Route path="/offres" element={<Offres />} />
          <Route path="/propositions-offres/:idPropositionOffre" element={<PropositionOffreDetail />} />
          <Route path="/mobilites/:idMobPart" element={<MobiliteDetails />} />
          <Route path="/mentions" element={<LegalMentions />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}
