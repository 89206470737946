import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface MbjCirclePercentCtrlProps {
  percent: number;
  className?: string;
}

const MbjCirclePercentCtrl = ({ percent, ...props }: MbjCirclePercentCtrlProps) => {
  const myColor = percent > 70 ? 'green' : percent > 40 ? 'orange' : 'red';
  return (
    <div className={`single-chart ${props.className}`}>
      <svg viewBox="0 0 36 36" className={`circular-chart ${myColor}`}>
        <path
          className="circle-bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${percent}, 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" className="percentage">
          {percent}%
        </text>
      </svg>
    </div>
  );
};

const Progress = (percent: number) => keyframes`
    from {
        stroke-dashoffset: ${percent};
    }

`;
export const MbjCirclePercent = styled(MbjCirclePercentCtrl)(
  (props) => ({
    '.circular-chart': {
      display: 'block',
      margin: ' 10px auto',
      maxWidth: '80%',
      maxHeight: '120px',
    },
    '.circle-bg': {
      fill: 'none',
      stroke: '#eee',
      strokeWidth: '3.8',
    },

    '.circle': {
      fill: 'none',
      strokeWidth: 2.8,
      strokeLinecap: 'round',
    },
    '.circular-chart.orange .circle': {
      stroke: '#ff9f00',
    },
    '.circular-chart.green .circle': {
      stroke: '#4CC790',
    },
    '.circular-chart.red .circle': {
      stroke: '#FF0000',
    },
    '.percentage': {
      fill: '#666',
      fontFamily: 'sans-serif',
      fontSize: '0.5em',
      textAnchor: 'middle',
    },
  }),
  css`
    animation: ${(props: MbjCirclePercentCtrlProps) => Progress(props.percent)} 1.5s ease-out;
  `
);
