import React from "react";
import styled from "styled-components";
import { MbjAvatar } from "../../atoms/MbjAvatar";

interface MbjMemberCardCtrlProps {
  //UserSimple type:
  logo?: string;
  name?: string;
  className?: string;
  children?: React.ReactNode;
  widthFull?: string;
}

const MbjMemberCardCtrl = (props: MbjMemberCardCtrlProps) => {
  return (
    <div className={`MemberCard ${props.className}`}>
      <div className={"badgeContainer"}>
        <MbjAvatar
          src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${props.logo}`}
          objectFit={"contain"}
          bg={"#ffffff"}
          name={props.name}
          size={"xl"}
        />
      </div>
      <div className={"infos-place"}>
        <div className={"Name-Member"}>{props.name}</div>
        {props.children && <div className={"infos-plus"}>{props.children}</div>}
      </div>
    </div>
  );
};
export const MbjMemberCard = styled(MbjMemberCardCtrl)((props) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "stretch",
  width: props.widthFull ? props.widthFull : "100%",
  ".badgeContainer": {
    display: "flex",
    alignItems: "center",
  },

  ".infos-place": {
    flexGrow: 1,
    padding: "0.25rem 0.5rem",
    ".Name-Member": {
      fontSize: "18px",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: props.theme.primary,
    },
    ".infos-plus": {
      marginTop: "10px",
    },
  },
}));
