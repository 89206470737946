import React from "react";
import styled from "styled-components";

function initials(name: string) {
  const [firstName, lastName] = name.split(" ");
  return firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : firstName.charAt(0);
}

interface MbjAvatarNameCtrlProps {
  /**
   * The name of the person in the avatar.
   *
   * - if `src` has loaded, the name will be used as the `alt` attribute of the `img`
   * - If `src` is not loaded, the name will be used to create the initials
   */
  name?: string;

  className?: string;
}
function MbjAvatarNameCtrl(props: MbjAvatarNameCtrlProps) {
  return (
    <div role="img" aria-label={props.name} className={props.className}>
      {props.name ? initials(props.name) : null}
    </div>
  );
}

/**
 * Composant contenant l'icône de l'utilisateur :
 */
export const MbjAvatarName = styled(MbjAvatarNameCtrl)((props) => ({}));
MbjAvatarName.defaultProps = {};
