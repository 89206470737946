import * as React from "react";
import styled from "styled-components";

export interface MbjCardFooterCtrlProps {
  /**
   * Classe HTML de la card?
   */
  className?: string;
  /**
   * Contenu de la card ?
   */
  children?: React.ReactNode;

  justify?: React.CSSProperties["justifyContent"];
}

function MbjCardFooterCtrl(props: MbjCardFooterCtrlProps) {
  return <div className={`MbjCardFooter ${props.className}`}>{props.children}</div>;
}
/**
 * Composant layout card :
 */
export const MbjCardFooter = styled(MbjCardFooterCtrl)<MbjCardFooterCtrlProps>((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: props.justify,
}));
MbjCardFooter.defaultProps = {
  justify: "flex-end",
};
