import React from "react";
import styled from "styled-components";
import { Operation } from "@mbj-front-monorepo/data-access";
import logope from "./logo-pe.png";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { MbjFlex } from "@mbj-front-monorepo/ui";

interface MbjOffrePeItemCtrlProps {
  className?: string;
  OffrePE: GetElementType<Operation<"getOffrePECollection">>;

  callToAction?: React.ReactNode;
}

const MbjOffrePeItemCtrl = (props: MbjOffrePeItemCtrlProps) => {
  return (
    <MbjFlex className={props.className} wrap={"nowrap"}>
      <div className={`logo-place`}>
        <img src={logope} alt={"logo pole emploi"} />
      </div>
      <MbjFlex grow={1} direction={"column"} gap={"10px"}>
        <MbjFlex justify={"space-between"} className={"header"}>
          <div>
            {props.OffrePE.libelle} - {props.OffrePE.metier?.libelle}
          </div>
          <div>{props.OffrePE.score ? `${Math.round(props.OffrePE.score * 10000) / 100}%` : ""}</div>
        </MbjFlex>
        <div className={"entreprise-place"}>
          {props.OffrePE.entrepriseName ? props.OffrePE.entrepriseName : "inconnue"}
        </div>
        <div className={`location-lib`}>{props.OffrePE.locationlib ? props.OffrePE.locationlib : ""}</div>
        <div className={`description-place`}>{props.OffrePE.description}</div>
        <MbjFlex justify={"space-between"} align={"flex-end"}>
          <div className={`date-place`}>{props.OffrePE.date ? getDateFR(new Date(props.OffrePE.date)) : ""}</div>
          {props.callToAction}
        </MbjFlex>
      </MbjFlex>
    </MbjFlex>
  );
};

export const MbjOffrePeItem = styled(MbjOffrePeItemCtrl)((props) => ({
  padding: "5px",
  borderBottom: `${props.theme.primaryLighten} solid 1px`,

  ".header": {
    fontWeight: "bold",
  },

  ".description-place": {
    whiteSpace: "pre-line",
  },

  ".date-place": {
    fontWeight: "bold",
  },
  ".entreprise-place": {
    color: props.theme.complementaryLighten,
    fontWeight: "bold",
  },
  ".location-lib": {
    color: props.theme.complementaryDarken,
    fontWeight: "bold",
  },

  ".logo-place": {
    padding: "0.5rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    img: {
      width: "80px",
      height: "auto",
    },
  },
}));
