import React from "react";

import styled from "styled-components/macro";
import { NavLink, To } from "react-router-dom";
import { BaseColorsTheme } from "../../../../assets/styles/ThemeType";

interface MbjItemMenuMobileCtrlProps {
  children?: any;
  /**
   * label de l'item menu
   */
  label?: string;
  /**
   * Classe HTML de l'ItemMenu
   */
  className?: string;

  /**
   * Couleur utilisée pour représenter l'ordre d'importance dee l'item Menu sur la page :
   */
  themeColor?: BaseColorsTheme;
  /**
   * Cet Item est-il actif (suis-je sur la page qu'il est censé représenter ?)
   */
  isActive?: boolean;
  /**
   * L'item Menu doit-il renvoyé sur une autre page ?
   *
   * A string representation of the Link location, created by concatenating the location’s pathname, search, and hash properties.
   * https://v5.reactrouter.com/web/api/Link/to-string
   */
  to: To;
  /**
   *
   */
  location?: string;
}

function MbjItemMenuMobileCtrl(props: MbjItemMenuMobileCtrlProps) {
  return (
    <NavLink to={props.to} className={props.className}>
      {React.cloneElement(props.children, { size: "25px" })}
      {<div className={"label"}>{props.label}</div>}
    </NavLink>
  );
}

/**
 * Composant représentant un "item" du menu de navigation principal sur mobile
 */
export const MbjItemMenuMobile = styled(MbjItemMenuMobileCtrl)((props) => ({
  all: "unset",
  display: "flex",
  flexDirection: "column",
  gap: "3px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "calc(8px + 0.75vmin)",

  color: !props.isActive ? props.theme.background2 : props.themeColor ? props.theme[props.themeColor] : "none",
  backgroundColor: !props.isActive ? props.theme.dark : props.theme.background2,
  cursor: "pointer",

  "&:hover, &.active": {
    color: props.themeColor ? props.theme[props.themeColor] : "none",
  },
  "@media only screen and (max-width: 260px)": {
    ".label": {
      display: "none",
    },
  },
}));
MbjItemMenuMobile.defaultProps = {
  themeColor: "secondary",
  isActive: false,
};
