import * as React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "@mbj-front-monorepo/utils";
import { MbjMenuLinks } from "../../../molecules/MbjMenu/MbjMenuLinks";
import { MbjMenuDesktop } from "../../../molecules/MbjMenu/desktop/MbjMenuDesktop";
import { MbjHeadBarDesktop } from "../../../molecules/MbjHeadBar/desktop/MbjHeadBarDesktop";

interface MainDesktopLayoutCtrlProps {
  /**
   * Classe HTML de desktop layout :
   */
  className?: string;
  /**
   * Représente le site vers lequel l'utilisateur peut aller (POUR DEBUG)
   */
  type?: string;
  /**
   * liens du menu :
   */
  menuLinks: MbjMenuLinks[];
  /**
   * fonction à appeler lors de la déconnexion :
   */
  disconnectionCallback?: () => void;
  /**
   * Logo de l'utilisateur
   */
  userLogo?: React.ReactElement;
}

function MainDesktopLayoutCtrl(props: MainDesktopLayoutCtrlProps) {
  const size = useWindowSize();

  return (
    <div className={"DesktopLayout " + props.className}>
      <MbjHeadBarDesktop className="MbjHeadBar" userLogo={props.userLogo} />
      <div className="main">
        <MbjMenuDesktop
          className="MbjMenu"
          menuLinks={props.menuLinks}
          isFold={!!(size.width && size.width < 550)}
          disonnectionCallback={props.disconnectionCallback}
        />

        <div className="section">
          {/*props.type*/}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export const MainDesktopLayout = styled(MainDesktopLayoutCtrl)((props) => ({
  width: "100vw",
  height: "100%",

  display: "flex",
  flexDirection: "column",

  "& .main": {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,

    "& .MbjMenu": {
      zIndex: 1,
      flexShrink: 0,
    },

    "& .section": {
      display: "flex",
      flexGrow: 1,
      overflowY: "hidden",
      position: "relative",
      backgroundColor: props.theme.background1,

      "& > *": {
        maxWidth: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      },
    },
  },
}));
