import { DefaultTheme } from "styled-components";
import logo from "../../images/sqy-mbj-v4.png";

export const sqyTheme: DefaultTheme = {
  //colors :
  //write in hexadecimal ! :

  primary: "#27348B",
  primaryDarken: "#1D2666",
  primaryLighten: "#A5BDD5",
  primaryPastel: "#9cacc9",

  secondary: "#1CA6E4",
  secondaryDarken: "#2D6580",
  secondaryLighten: "#94D8F7",
  secondaryPastel: "#DAEBF2",

  third: "#D7C378",
  thirdDarken: "#807241",
  thirdLighten: "#F2DD91",
  thirdPastel: "#F2EDDA",

  complementary: "#E13000",
  complementaryDarken: "#804841",
  complementaryLighten: "#D99F98",
  complementaryPastel: "#F2DDDA",

  grey: "#868686",
  greyDarken: "#616161",
  greyLighten: "#B5B5B5",
  greyPastel: "#E6E6E6",

  warning: "#FF0000",
  warningDarken: "#9E0E1D",
  warningLighten: "#D64251",
  warningPastel: "#F77986",

  dark: "#171E52",
  lightText: "#868686",
  background1: "#F2F2F2",
  background2: "#FFFFFF",

  //font size :
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
  },

  logo: logo,
};
