import * as AccordionPrimitive from '@radix-ui/react-accordion';
import styled from 'styled-components';

interface MbjAccordionHeaderProps {}

export const MbjAccordionHeader = styled(AccordionPrimitive.Header)<MbjAccordionHeaderProps>((props) => ({
  //all: "unset",
  display: 'flex',
}));
MbjAccordionHeader.defaultProps = {};
