import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MbjMenuLinks } from "../MbjMenuLinks";
import { MbjItemMenuMobile } from "../../../atoms/MbjItemMenu/mobile/MbjItemMenuMobile";

interface MbjMenuMobileCtrlProps {
  /**
   * Classe HTML du Menu
   */
  className?: string;
  /**
   * Liens du menu :
   */
  menuLinks: MbjMenuLinks[];

  /**
   * Couleur utilisée pour représenter l'ordre d'importance du Menu sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Hauteur du menu
   */
  height?: string;
  /**
   * Largeur du menu
   */
  width?: string;
}

function MbjMenuMobileCtrl(props: MbjMenuMobileCtrlProps) {
  const navigate = useNavigate();

  return (
    <div className={props.className}>
      <nav className="NavContainer">
        {props.menuLinks.map((link, index) => (
          // eslint-disable-next-line react/jsx-no-undef
          <MbjItemMenuMobile label={link.label} to={link.to} themeColor={props.themeColor} key={index}>
            {link.icon}
          </MbjItemMenuMobile>
        ))}
      </nav>
    </div>
  );
}

export const MbjMenuMobile = styled(MbjMenuMobileCtrl)((props) => ({
  overflowX: "hidden",
  overflowY: "auto",
  backgroundColor: props.theme.dark,
  minWidth: "fit-content",
  boxShadow: "6px 0px 8px -2px #0000004f",
  height: props.height,
  width: props.width,
  padding: "4px 15px",

  ".NavContainer": {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: "8px",

    "& > *": {
      flex: "1 1 0px",
    },
  },
}));
MbjMenuMobile.defaultProps = {
  themeColor: "secondary",
  height: "10vh",
  width: "100vw",
};
