import React from "react";
import "./main.css";
import App from "./main/App";
import { BrowserRouter } from "react-router-dom";
import { AnchorScroller } from "./components/AnchorScroller";
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AnchorScroller>
        <App />
      </AnchorScroller>
    </BrowserRouter>
  </React.StrictMode>
);
