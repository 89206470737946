import { useState } from "react";

export interface useModalHook {
  isShowing: boolean;
  toggle: () => void;
}

export const useModal = (visible = false): useModalHook => {
  const [isShowing, setIsShowing] = useState(visible);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
  };
};
