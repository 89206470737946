import * as AccordionPrimitive from "@radix-ui/react-accordion";
import styled from "styled-components";

interface MbjAccordionItemProps {}

export const MbjAccordionItem = styled(AccordionPrimitive.Item)<MbjAccordionItemProps>((props) => ({
  overflow: "hidden",
  marginTop: 1,

  "&:first-child": {
    marginTop: 0,
    // borderTopLeftRadius: "0.4em",
    // borderTopRightRadius: "0.4em",
  },

  "&:last-child": {
    // borderBottomLeftRadius: "0.4em",
    // borderBottomRightRadius: "0.4em",
  },
}));
MbjAccordionItem.defaultProps = {};
