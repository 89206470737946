import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { MbjLoading, MbjNavPath } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { useGetParticulierMe } from "@mbj-front-monorepo/data-access";
import { MbjParticulierInformationsCard } from "@mbj-front-monorepo/data-ui";

interface InformationsCtrlProps {
  className?: string;
}

function InformationsCtrl(props: InformationsCtrlProps) {
  const location = useLocation();
  const particulierQuery = useGetParticulierMe();
  return (
    <div className={props.className}>
      <MbjNavPath routePath={location.pathname} />
      {particulierQuery.isLoading || particulierQuery.isError ? (
        <MbjLoading />
      ) : (
        <MbjParticulierInformationsCard particulierId={particulierQuery.data?.id || -1} />
      )}
    </div>
  );
}
export const Informations = styled(InformationsCtrl)((props) => ({
  padding: "1% 2%",
  overflowY: "auto",
}));
