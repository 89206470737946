import * as TabsPrimitive from "@radix-ui/react-tabs";
import { TabsListProps } from "@radix-ui/react-tabs";
import styled from "styled-components";
import * as React from "react";

interface MbjTabsListProps extends TabsListProps {
  isFitted?: boolean;
}

const MbjTabsListCtrl = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & MbjTabsListProps
>(({ isFitted, ...rest }, forwardedRef) => {
  return (
    <TabsPrimitive.List {...rest} ref={forwardedRef} data-is-fitted={isFitted}>
      {rest.children}
    </TabsPrimitive.List>
  );
});

export const MbjTabsList = styled(MbjTabsListCtrl)<MbjTabsListProps>((props) => ({
  flexShrink: 0,
  display: "flex",
  borderBottom: `2px solid ${props.theme.primaryPastel}`,
  borderRadius: "0.4em 0.4em 0 0",
  overflow: "hidden",

  '&[data-orientation="vertical"]': {
    flexDirection: "column",
    height: "100%",
    borderRadius: "0.4em 0 0 0.4em",
    borderRight: `2px solid ${props.theme.primaryPastel}`,
    borderBottom: "none",
    backgroundColor: props.theme.background2,
  },
  '&[data-is-fitted="true"]': {
    "& > *": {
      flex: 1,
    },
  },
}));
MbjTabsList.defaultProps = {
  isFitted: false,
};
